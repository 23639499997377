
const AboutFestivals = () => {
    return (
        <section className="welcome-festival">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <div className="welcome-content">
                            <div className="heading">

                                <h2 className="head-1">Recent Events</h2>
                            </div>
                            <p className="b-line"></p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutFestivals