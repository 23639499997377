import PageHeader from "../components/common/PageHeader";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { Modal } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import ApiService from "../api/ApiService";



const TemplePhotoList = () => {
    const scrollToElement = () => {
        const element = document.getElementById('scrollToAllPages');
        // console.log("elements",element);
        if(element) {
          element.scrollIntoView({behavior : 'smooth'})
        }
      };
    const [meta, setMeta] = useState(null)
    const fetchMeta = async (pagename) => {
        try {
            let res = await ApiService.fetchMetaData(pagename)
            setMeta(res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchMeta('temple_photolist')
    }, [])
    useEffect(()=>{

        scrollToElement();
        },[])

    return (
        <>
            <MetaData title="Temple Photo" />
            <PageHeader
                title="Temple Photo"
                imgSrc="/images/page-header/20.webp"
            />
            <section className="templephoto-heading-sec">
                <div className="container">
                    <div className="row" id="scrollToAllPages">
                        <div className="col-lg-10 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">

                                    Temple activities photos will be uploaded soon.
                                </h2>
                                <p>
                                    {/* The Govinda’s Cafe at ISKCON Attapur offers very delicious Krishna prasad for all the visiting devotees. You can relish the taste of purity in Satvik Indian, Chinese, other cuisines. */}
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <GoToTop />
        </>
    );
};

export default TemplePhotoList;
