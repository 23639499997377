import React, { useEffect, useState } from "react";
import PageHeader from "../components/common/PageHeader";
import MetaData from "../helpers/MetaData";
import { Link } from "react-router-dom";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import GoToTop from "../helpers/GoToTop";

const Sitemap = () => {
  const [campaigns, setCampaigns] = useState(null);
  const fetchCampaigns = async () => {
    try {
      const res = await ApiService.fetchCampaigns();
      setCampaigns(res);
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  return (
    <>
      <MetaData title="Sitemap - ISKCON of Attapur" />
      <PageHeader title="sitemap" imgSrc="/images/page-header/10.webp" />
      <section className="sitemap">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="heading  d-flex align-items-center  flex-column">
                <h2 className="head-1 border_bottom pb-2">Site Map</h2>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="sitemap-content">
                <h4>Pages</h4>
                <ul className="list-unstyled main_menu mt-4">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About</Link>
                    <ul className="list-unstyled sub_menu">
                      <li>
                        <Link to="/about-us/#mission">ISKCON Mission</Link>
                      </li>
                      <li>
                        <Link to="/about-us/#founder">Founder</Link>
                      </li>
                      <li>
                        <Link to="/about-us/#temple">
                          Sri Sri Radha Madhava Temple
                        </Link>
                      </li>
                      <li>
                        <Link to="/gaudiya-vaishnavism">
                          Gaudiya Vaishnavism
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/courses">Courses</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/movement">HARE KRISHNA MOVEMENT</Link>
                  </li>
                  <li>
                    <a href="https://iskcontsp.org/" target="_blank">
                      Harinam Sankirtan 
                    </a>
                  </li>
                  <li>
                    <Link to="/festivals">Festivals</Link>
                  </li>
                  <li>
                    <Link to="/yatras">Yatras</Link>
                  </li>
                  <li>
                    <Link to="/donation-form">Quick Donation</Link>
                  </li>
                  <li>
                    <Link to="/volunteer">volunteer</Link>
                  </li>
                  <li>
                    <Link to="/annadaan">Food for life</Link>
                  </li>
                  <li>
                    <Link to="/connect">connect</Link>
                  </li>
                  <li>
                    <Link to="/donate">DONATE</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sitemap-content">
                <h4>campaigns</h4>
                <ul className="list-unstyled main_menu mt-4">
                    {
                        campaigns?.map(camp => (
                            <li key={camp?._id}>
                                <Link to={`/${camp.slug}`}>{camp.title}</Link>
                            </li>
                        ))
                    }
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sitemap-content">
                <h4>terms</h4>
                <ul className="list-unstyled main_menu mt-4">
                  <li>
                    <Link to="/terms-and-condition">terms and condition</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">privacy policy</Link>
                  </li>
                  <li>
                    <Link to="/refund-policy">refund policy</Link>
                  </li>
                  <li>
                    <Link to="/recurring-payments-terms">Recurring Payments Terms</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GoToTop />
    </>
  );
};

export default Sitemap;
