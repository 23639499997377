import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../components/common/PageHeader";
import MetaData from "../helpers/MetaData";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import { Tab, Tabs, Modal } from "react-bootstrap";
import { HiUser } from "react-icons/hi";
import Accordion from "react-bootstrap/Accordion";

const CsrNew = () => {
  const [readMoreModal1, setReadMoreModal1] = useState(false);
  const [readMoreModal2, setReadMoreModal2] = useState(false);
  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    if (slider1 && slider2) {
      slider1.current?.sync(slider2.current?.splide);
    }
  }, [slider1, slider2]);

  return (
    <>
      <MetaData title="Corporate Social Responsibility - ISKCON of Attapur" />
      <section className="csr-new banner">
        <div className="text-center text-lg-start container">
          <div className=" heading dual-heading center">
            <h2 className="text-uppercase primary-heading">
              ISKCON Attapur <span>Csr</span>
            </h2>
          </div>
          <div className="row mt-5">
            <div className="col-lg-5 d-lg-flex justify-content-center align-items-center">
              <div className="banner-content-wrap">
                <h3 className="head-3">
                  Creating a Better Society for Tomorrow
                </h3>
                <p>
                  Corporate social responsibility is a form of international
                  private business self-regulation which aims to contribute to
                  societal goals of a philanthropic, activist, or charitable
                  nature by engaging in or supporting volunteering or ethically
                  oriented practices.
                </p>
              </div>
            </div>
            <div className="col-lg-7 mt-3">
              <figure>
                <img
                  src="/images/csr/banner-image.jpg"
                  alt=""
                  className="banner-img"
                />
              </figure>
            </div>
          </div>
        </div>
        <figure className="d-none d-lg-block">
          <img
            src="/images/csr/banner-image.jpg"
            alt=""
            className="banner-lg-img"
          />
        </figure>
      </section>
      <section className="csr-new annadaan-wrap pt-lg-5">
        <div className="container">
          <div className="d-md-flex gap-md-2 gap-lg-5 justify-content-around align-items-center">
            <a href="#annadaan">
              <div className="annadaan-card">
                <img src="/images/csr/csr-icon-1.png" alt="" />
                <span>Annadaan</span>
              </div>
            </a>
            <a href="#green-initiatives">
              <div className="annadaan-card">
                <img src="/images/csr/csr-icon-2.png" alt="" />
                <span>Green Initiatives</span>
              </div>
            </a>
            <a href="#value-education">
              <div className="annadaan-card">
                <img src="/images/csr/csr-icon-3.png" alt="" />
                <span>Value Education</span>
              </div>
            </a>
          </div>
        </div>
      </section>
      <section className="csr-new annadaan" id="annadaan">
        <div className="container">
          <div>
            <div className="heading">
              <h2 className="head-1 text-uppercase">Annadaan</h2>
            </div>

            <div className="annadaan-content-wrap">
              <div className="d-none d-md-block">
                <figure>
                  <img src="/images/csr/Annadaan.jpg" alt="" />
                  <span className="text-uppercase">Activities</span>
                </figure>
                <div className="annadaan-content">
                  <h1>
                    Annadaan or free food distribution program, is one of our
                    most important activity
                  </h1>
                  <ul className="mt-3">
                    <li>
                      <span>
                        Daily 1000-1500 meals are being distributed since April
                        2020.
                      </span>
                    </li>
                    <li>
                      <span>
                        Mega food distribution drives on festivals like Makar
                        Sankranti, Ram Navami, Janmashtami & Diwali.
                      </span>
                    </li>
                    <li>
                      <span>
                        More than 6 Lakh meals already distributed in the
                        calendar year 2022.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-md-none">
                <img src="/images/csr/Annadaan.jpg" className="w-100" alt="" />
                <div className="annadaan-content-sm">
                  <h1>
                    Annadaan or free food distribution program, is one of out
                    most important activity
                  </h1>
                  <ul className="mt-lg-5">
                    <li>
                      Daily 1000-1500 meals are being distributed since April
                      2020
                    </li>
                    <li>
                      Mega food distribution drives on festivals like Makar
                      Sankranti, Ram Navami, Janmastami & Diwali.
                    </li>
                    <li>
                      More than 6 Lakh meals already distributed in the calendar
                      year 2022.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="csr-new annadaan-table">
        <div className="container">
          <div className="csr-wrap">
            <div className="row csr-table-wrap ">
              <div className="col-12 p-0">
                <div className="_header">
                  <h4 className="head-title">
                    Below is the glimpse of our Food Distribution activity in
                    last 2 years.
                  </h4>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="row">
                  <div className="col-sm-8 col-7 pe-0">
                    <div className="tab-col left">
                      <h5 className="head-title">Period</h5>
                    </div>
                  </div>
                  <div className="col-sm-4 col-5 ps-0">
                    <div className="tab-col border-start-0">
                      <h5 className="head-title">
                        Number of food packets distributed
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="row">
                  <div className="col-sm-8 col-7 pe-0">
                    <div className="tab-col left">
                      <p>April - Dec 2020</p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-5 ps-0">
                    <div className="tab-col border-start-0">
                      <p>2,70,000</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="row">
                  <div className="col-sm-8 col-7 pe-0">
                    <div className="tab-col left">
                      <p>Jan - Dec 2021</p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-5 ps-0">
                    <div className="tab-col border-start-0">
                      <p>5,60,000</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="row">
                  <div className="col-sm-8 col-7 pe-0">
                    <div className="tab-col left">
                      <p>Jan - Mar 2022</p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-5 ps-0">
                    <div className="tab-col border-start-0">
                      <p>1,20,000</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="row">
                  <div className="col-sm-8 col-7 pe-0">
                    <div className="tab-col left">
                      <p>April - Jun 2022 (50K meals on Ram Navami)</p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-5 ps-0">
                    <div className="tab-col border-start-0">
                      <p>1,50,000</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="row">
                  <div className="col-sm-8 col-7 pe-0">
                    <div className="tab-col left">
                      <p>Jul - Sep 2022 (1 lakh meals on Janmashtami)</p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-5 ps-0">
                    <div className="tab-col border-start-0">
                      <p>2,30,000</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="row">
                  <div className="col-sm-8 col-7 pe-0">
                    <div className="tab-col left">
                      <p>Projected Q4'22 (50K meals on Diwali)</p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-5 ps-0">
                    <div className="tab-col border-start-0">
                      <p>2,00,000</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="future-plan campaign-main-sec border-0 csr-new">
        <div className="container">
          <div className="dummy-line row justify-content-between align-items-center">
            <div className="col-lg-6 ms-lg-5">
              <div className="heading text-start ms-lg-5">
                <h2 className="head-1 text-uppercase mb-lg-5">
                  OUR FUTURE PLAN
                </h2>
                <p style={{lineHeight:'30px'}}>
                  Given that food distribution is the most important activity
                  for ISKCON Attapur, in 2023, we plan to distribute over 10
                  lakh free meals to the needy. In 2024, we will work towards
                  increasing the distribution to 12 lakh meals.
                </p>
                <p style={{lineHeight:'30px'}}>
                  Attapur has over 10 lakh daily wage earners and a sizeable
                  portion of the city population is below the poverty line. When
                  people living below the poverty line, even though they may be
                  daily wage earners, are guaranteed one hot meal for all
                  members of their family, that is one less expense for them,
                  which automatically leads to savings that can be used towards
                  necessities such as medical, education etc. Thus, our efforts
                  are factually leading to a better standard of life for the
                  underprivileged.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="story-slider">
                <div className="image-preview mt-4 mt-lg-0">
                  <Splide
                    ref={slider2}
                    options={{
                      type: "fade",
                      rewind: true,
                      pagination: false,
                      arrows: false,
                    }}
                  >
                    <SplideSlide className="image-preview-slide">
                      <figure className="mb-0">
                        <img src="/images/csr/annadan.webp" alt="" />
                      </figure>
                    </SplideSlide>
                    <SplideSlide className="image-preview-slide">
                      <figure className="mb-0">
                        <img src="/images/csr/future-plan-1.webp" alt="" />
                      </figure>
                    </SplideSlide>
                    <SplideSlide className="image-preview-slide">
                      <figure className="mb-0">
                        <img src="/images/csr/future-plan-2.webp" alt="" />
                      </figure>
                    </SplideSlide>
                  </Splide>
                </div>
                <div className="thumbnail-slider-wrap mb-5 mb-lg-0">
                  <Splide
                    ref={slider1}
                    options={{
                      gap: 10,
                      pagination: false,
                      cover: true,
                      arrows: false,
                      perPage: 3,
                      height: 120,
                      heightRatio: 0.5,
                      autoplay: true,
                      speed: 1000,
                      interval: 5000,
                      perMove: 1,
                      type: "loop",
                    }}
                  >
                    <SplideSlide className="thumbnail-slide">
                      <figure className="mb-0">
                        <img src="/images/csr/annadan.webp" alt="" />
                      </figure>
                    </SplideSlide>
                    <SplideSlide className="thumbnail-slide">
                      <figure className="mb-0">
                        <img src="/images/csr/future-plan-1.webp" alt="" />
                      </figure>
                    </SplideSlide>
                    <SplideSlide className="thumbnail-slide">
                      <figure className="mb-0">
                        <img src="/images/csr/future-plan-2.webp" alt="" />
                      </figure>
                    </SplideSlide>
                  </Splide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="csr-new partner-with-us">
        <div className="container">
          <div className="heading text-start">
            <h2 className="head-1 text-center text-uppercase">
              PARTNER WITH US
            </h2>
          </div>
          <div className="partner-feature-cards-wrap">
            <div className="row">
              <div className="col-lg-8 mt-2 mt-lg-0">
                <div className="partner-feature-card">
                  <p>
                    Support the Daily Food Distribution activity, one meal costs Rs 30/-
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mt-2 mt-lg-0">
                <div className="partner-feature-card">
                  <p>Sponsor Kitchen Equipment's</p>
                </div>
              </div>
            </div>
            <div className="row mt-lg-4 mt-2">
              <div className="col-lg-4">
                <div className="partner-feature-card">
                  <p>Sponsor Food Distribution Vehicle</p>
                </div>
              </div>
              <div className="col-lg-8 mt-2 mt-lg-0">
                <div className="partner-feature-card">
                  <p>
                    Sponsor Installation of Solar Panels to Generate Green
                    Energy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-5">
          <div className="csr-partners-slider mb-5">
            <Splide
              options={{
                type: "loop",
                rewind: true,
                start: "5",
                focus: "center",
                pagination: false,
                arrows: false,
                perPage: 6,
                gap: 30,
                drag: "free",
                breakpoints: {
                  576: {
                    perPage: 2,
                  },
                  1200: {
                    perPage: 5,
                  },
                  991: {
                    perPage: 4,
                  },
                },
                autoScroll: {
                  pauseOnHover: false,
                  pauseOnFocus: false,
                  rewind: false,
                  speed: 1,
                },
              }}
              extensions={{ AutoScroll }}
            >
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-1.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-2.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-3.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-4.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-5.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-6.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-7.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-8.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-9.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-10.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-11.webp" alt="" />
                </figure>
              </SplideSlide>
            </Splide>
          </div>
          <div className="csr-partners-slider">
            <Splide
              options={{
                type: "loop",
                rewind: true,
                pagination: false,
                arrows: false,
                perPage: 6,
                gap: 30,
                drag: "free",
                breakpoints: {
                  576: {
                    perPage: 2,
                  },
                  1200: {
                    perPage: 5,
                  },
                  991: {
                    perPage: 4,
                  },
                },
                autoScroll: {
                  pauseOnHover: false,
                  pauseOnFocus: false,
                  rewind: false,
                  speed: 1,
                },
              }}
              extensions={{ AutoScroll }}
            >
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-1.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-2.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-3.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-4.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-5.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-6.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-7.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-8.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-9.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-10.webp" alt="" />
                </figure>
              </SplideSlide>
              <SplideSlide className="partner-slide">
                <figure className="mb-0">
                  <img src="/images/csr/partner-11.webp" alt="" />
                </figure>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </section>
      <section className="csr-new green-init" id="green-initiatives">
        <div className="container">
          <div className="row mb-lg-2 mb-4">
            <div className="col-lg-8 mx-auto">
              <div className="heading _main">
                <h2 className="head-1 text-uppercase text-uppercase ">
                  Green Initiatives
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="heading">
                <h4 className="text-uppercase">ABOUT RAJANNA SIRCILLA</h4>
              </div>
            </div>
            <p className="text-center">
              Rajanna Sircilla is a town and district located on the banks of
              Maneru river in the Sircilla mandal, about 180 kms from Hyderabad
              city.
            </p>
          </div>
          <div className="d-md-none">
            <Accordion defaultActiveKey={["0"]}>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="text-uppercase">
                  About ISKCON Sircilla
                </Accordion.Header>
                <Accordion.Body>
                  <div className="green-init-acc-card">
                    <div className="row align-items-center">
                      <div className="order-1 order-lg-0">
                        <Splide
                          options={{
                            type: "loop",
                            rewind: true,
                            perPage: 1,
                            perMove: 1,
                            pagination: true,
                            arrows: false,
                            autoplay: true,
                            interval: 4000,
                            breakpoints: {
                              320: {
                                perPage: 1,
                              },
                              768: {
                                perPage: 1,
                              },
                              992: {
                                perPage: 1,
                              },
                            },
                          }}
                        >
                          <SplideSlide className="green-init-slide">
                            <figure className="mb-0 _first">
                              <img src="/images/csr/temp-1.png" alt="" />
                            </figure>
                          </SplideSlide>
                          <SplideSlide className="green-init-slide">
                            <figure className="mb-0 _first">
                              <img src="/images/csr/temp-1.png" alt="" />
                            </figure>
                          </SplideSlide>
                        </Splide>
                      </div>
                      <div className="tab-inner-content">
                        <h4 className="text-uppercase">
                          ABOUT ISKCON SIRCILLA
                        </h4>
                        <p>
                          ISKCON Bhiwandi and ISKCON Attapur-Hyderabad, acquired 16.89 acres of farm land in the city of Sircilla with the aim of having a closer relationship with mother nature by cultivating organic farming.
                        </p>
                        <p>
                          70% of our land has been earmarked for green
                          initiatives such as plantation of trees and plants,
                          including vegetable, fruit and flower bearing ones.
                          Water conservation measures will also be taken to
                          ensure optimum green efficiency.
                        </p>
                        <p>
                          A portion of the land will be used for a goshala, where cows will be nurtured and protected. This will aid our objective of full organic farming, as the waste of the cows will be used as manure for the land.
                        </p>
                        <p>
                          We will also conduct farmer training programs and livestock protection programs for the benefit of the local farmers of the district. Apart from these core activities, and in line with one of ISKCON's core principles, we will be serving out meals to the needy section of the local population as part of our Food for Life program.
                        </p>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="text-uppercase">
                  Our Plans
                </Accordion.Header>
                <Accordion.Body>
                  <div className="green-init-acc-card">
                    <div className="row align-items-center">
                      <div className="order-1 order-lg-0">
                        <Splide
                          options={{
                            type: "loop",
                            rewind: true,
                            perPage: 1,
                            perMove: 1,
                            pagination: true,
                            arrows: false,
                            autoplay: true,
                            interval: 4000,
                            breakpoints: {
                              320: {
                                perPage: 1,
                              },
                              768: {
                                perPage: 1,
                              },
                              992: {
                                perPage: 1,
                              },
                            },
                          }}
                        >
                          <SplideSlide className="green-init-slide">
                            <figure className="mb-0 _first">
                              <img src="/images/csr/temp-1.png" alt="" />
                            </figure>
                          </SplideSlide>
                          <SplideSlide className="green-init-slide">
                            <figure className="mb-0 _first">
                              <img src="/images/csr/temp-1.png" alt="" />
                            </figure>
                          </SplideSlide>
                        </Splide>
                      </div>
                      <div className="order-0 order-lg-1">
                        <div className="tab-inner-content">
                          <h4 className="text-uppercase">OUR PLANS</h4>
                          <p>
                            A boundary fence has been erected to safeguard the entire property. 7.5 acres of land has been levelled to make it ready for plantation.
                          </p>
                          <p>
                            In phase one, the plantation of trees on 5.5 acres has begun. Plantation on another 2 acres will begin in December 2022.
                          </p>
                          <p>
                            In phase two, the remaining land will be leveled and plantation will be done on 4.5 acres of land. We will also build a Goshala and a facility for conducting training programs.
                          </p>
                          <p>
                            We aim to plant more than 2000 trees which will cover all three major categories i.e. flowers, fruits and vegetables.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="text-uppercase">
                  Partner With Us
                </Accordion.Header>
                <Accordion.Body>
                  <div className="green-init-acc-card">
                    <div className="row align-items-center">
                      <div className="order-1 order-lg-0">
                        <Splide
                          options={{
                            type: "loop",
                            rewind: true,
                            perPage: 1,
                            perMove: 1,
                            pagination: true,
                            arrows: false,
                            autoplay: true,
                            interval: 4000,
                            breakpoints: {
                              320: {
                                perPage: 1,
                              },
                              768: {
                                perPage: 1,
                              },
                              992: {
                                perPage: 1,
                              },
                            },
                          }}
                        >
                          <SplideSlide className="green-init-slide">
                            <figure className="mb-0 _first">
                              <img src="/images/csr/temp-1.png" alt="" />
                            </figure>
                          </SplideSlide>
                          <SplideSlide className="green-init-slide">
                            <figure className="mb-0 _first">
                              <img src="/images/csr/temp-1.png" alt="" />
                            </figure>
                          </SplideSlide>
                        </Splide>
                      </div>
                      <div className=" order-0 order-lg-1 ">
                        <div className="tab-inner-content">
                          <h4 className="text-uppercase">PARTNER WITH US</h4>
                          <p>
                            Support the 1st phase of tree plantation activity at
                            ISKCON Sircilla.
                          </p>
                          <p>
                            Support the procurement of capital assets which will
                            assist the farm with plantation, organic farming and
                            live stock development.
                            <ul className="list-unstyled">
                              <li className="bullet">Sponsor a tractor</li>
                              <li className="bullet">
                                Sponsor a pick-up truck
                              </li>
                              <li className="bullet">
                                Sponsor installation of solar panels to generate
                                green energy and have a natural source of
                                electricity supply at the farm
                              </li>
                              <li className="bullet">Sponsor Water Tankers</li>
                            </ul>
                          </p>
                          <p>
                            Support the 2nd phase of tree plantation activity
                            which includes further levelling of the soil and
                            installing a water dripping system along with
                            irrigation pipes.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="d-none d-md-block">
            <div className="csr-green-init-tab-wrap mt-md-5 mt-4">
              <Tabs
                defaultActiveKey="about"
                id="csr-green-init"
                className="csr-green-init-tabs"
              >
                <Tab eventKey="about" title="ABOUT ISKCON SIRCILLA">
                  <div className="row align-items-center">
                    <div className="col-lg-4 order-1 order-lg-0">
                      <Splide
                        options={{
                          type: "loop",
                          rewind: true,
                          perPage: 1,
                          perMove: 1,
                          pagination: true,
                          arrows: false,
                          autoplay: false,
                          interval: 4000,
                          breakpoints: {
                            320: {
                              perPage: 1,
                            },
                            992: {
                              perPage: 2,
                            },
                          },
                        }}
                      >
                        <SplideSlide className="green-init-slide">
                          <figure className="mb-0 _first">
                            <img src="/images/csr/temp-1.png" alt="" />
                          </figure>
                        </SplideSlide>
                        <SplideSlide className="green-init-slide">
                          <figure className="mb-0 _first">
                            <img src="/images/csr/temp-1.png" alt="" />
                          </figure>
                        </SplideSlide>
                        <SplideSlide className="green-init-slide">
                          <figure className="mb-0 _first">
                            <img src="/images/csr/temp-1.png" alt="" />
                          </figure>
                        </SplideSlide>
                      </Splide>
                    </div>
                    <div className="col-lg-8 ps-xl-5 order-0 order-lg-1 pt-lg-2 pe-lg-4">
                      <div className="tab-inner-content px-3 px-lg-0 pt-lg-0 pt-2">
                        <h4 className="text-uppercase">
                          ABOUT ISKCON SIRCILLA
                        </h4>
                        <p>
                          ISKCON Bhiwandi and ISKCON Attapur-Hyderabad, acquired 16.89 acres of farm land in the city of Sircilla with the aim of having a closer relationship with mother nature by cultivating organic farming.
                        </p>
                        <p>
                          70% of our land has been earmarked for green
                          initiatives such as plantation of trees and plants,
                          including vegetable, fruit and flower bearing ones.
                          Water conservation measures will also be taken to
                          ensure optimum green efficiency.
                        </p>
                        <p>
                          A portion of the land will be used for a goshala, where cows will be nurtured and protected. This will aid our objective of full organic farming, as the waste of the cows will be used as manure for the land.
                        </p>
                        <p>
                          We will also conduct farmer training programs and
                          livestock protection programs for the benefit of local
                          farmers of the district. Apart from these core
                          activities, and in line with one of ISKCON's core
                          principles, we will be serving out meals to the needy
                          section of the local population as part of our Food
                          for Life program.
                        </p>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="plans" title="OUR PLANS">
                  <div className="row align-items-center">
                    <div className="col-lg-4 order-1 order-lg-0 mt-4 mt-lg-0">
                      <Splide
                        options={{
                          type: "loop",
                          rewind: true,
                          perPage: 1,
                          perMove: 1,
                          pagination: true,
                          arrows: false,
                          autoplay: false,
                          interval: 4000,
                          breakpoints: {
                            320: {
                              perPage: 1,
                            },
                            992: {
                              perPage: 2,
                            },
                          },
                        }}
                      >
                        <SplideSlide className="green-init-slide">
                          <figure className="mb-0 _first">
                            <img src="/images/csr/temp-1.png" alt="" />
                          </figure>
                        </SplideSlide>
                        <SplideSlide className="green-init-slide">
                          <figure className="mb-0 _first">
                            <img src="/images/csr/temp-1.png" alt="" />
                          </figure>
                        </SplideSlide>
                      </Splide>
                    </div>
                    <div className="col-lg-8 order-0 order-lg-1 ps-xl-5 pt-lg-2 pe-lg-4">
                      <div className="tab-inner-content px-3 px-lg-0 pt-lg-0 pt-2">
                        <h4 className="text-uppercase">OUR PLANS</h4>
                        <p>
                          A boundary fence has been erected to safeguard the entire property. 7.5 acres of land has been levelled to make it ready for plantation.
                        </p>
                        <p>
                          In phase one, the plantation of trees on 5.5 acres has begun. Plantation on another 2 acres will begin in December 2022.
                        </p>
                        <p>
                          In phase two, the remaining land will be leveled and plantation will be done on 4.5 acres of land. We will also build a Goshala and a facility for conducting training programs.
                        </p>
                        <p>
                          We aim to plant more than 2000 trees which will cover all three major categories i.e. flowers, fruits and vegetables.
                        </p>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="partner" title="PARTNER WITH US">
                  <div className="row align-items-center">
                    <div className="col-lg-4 order-1 order-lg-0">
                      <Splide
                        options={{
                          type: "loop",
                          rewind: true,
                          perPage: 1,
                          perMove: 1,
                          pagination: true,
                          arrows: false,
                          autoplay: false,
                          interval: 4000,
                          breakpoints: {
                            320: {
                              perPage: 1,
                            },
                            992: {
                              perPage: 2,
                            },
                          },
                        }}
                      >
                        <SplideSlide className="green-init-slide">
                          <figure className="mb-0 _first">
                            <img src="/images/csr/temp-1.png" alt="" />
                          </figure>
                        </SplideSlide>
                        <SplideSlide className="green-init-slide">
                          <figure className="mb-0 _first">
                            <img src="/images/csr/temp-1.png" alt="" />
                          </figure>
                        </SplideSlide>
                      </Splide>
                    </div>
                    <div className="col-lg-8 ps-xl-5 order-0 order-lg-1 py-lg-2 pe-lg-4">
                      <div className="tab-inner-content px-3 px-lg-0 pt-lg-0 pt-2">
                        <h4 className="text-uppercase">PARTNER WITH US</h4>
                        <p>
                          Support the 1st phase of tree plantation activity at
                          ISKCON Sircilla.
                        </p>
                        <p>
                          Support the procurement of capital assets which will
                          assist the farm with plantation, organic farming and
                          live stock development.
                          <ul className="list-unstyled">
                            <li className="bullet">Sponsor a tractor</li>
                            <li className="bullet">Sponsor a pick-up truck</li>
                            <li className="bullet">
                              Sponsor installation of solar panels to generate
                              green energy and have a natural source of
                              electricity supply at the farm
                            </li>
                            <li className="bullet">Sponsor Water Tankers</li>
                          </ul>
                        </p>
                        <p>
                          Support the 2nd phase of tree plantation activity
                          which includes further levelling of the soil and
                          installing a water dripping system along with
                          irrigation pipes.
                        </p>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
      <section className="csr-new value-education yt-links-sec pt-0" id="value-education">
        <div className="container">
          <div className="row mb-4 mb-lg-2">
            <div className="col-lg-8 mx-auto">
              <div className="heading _main text-center">
                <h2 className="head-1 text-uppercase ">Value Education</h2>
              </div>
            </div>
          </div>
          <div className="video-wrap">
            <div className="row justify-content-center">
              <div className="col-12 mb-5 pt-lg-3">
                <div className="heading text-center text-uppercase">
                  <h4>EMPOWERING INDIA WITH VALUES</h4>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3 mb-sm-5">
                <div className="value-education-card">
                  <figure>
                    <img src="/images/csr/1.jpg" alt="concentration" />
                  </figure>
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <h4>Concentration</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="value-education-card">
                  <figure>
                    <img src="/images/csr/2.jpg" alt="Truthfulness" />
                  </figure>
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <h4>Truthfulness</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3 mb-sm-5">
                <div className="value-education-card">
                  <figure>
                    <img src="/images/csr/3.jpg" alt="Discipline" />
                  </figure>
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <h4>Discipline</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="value-education-card">
                  <figure>
                    <img src="/images/csr/4.jpg" alt="Dutiful" />
                  </figure>
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <h4>Dutiful</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6  mb-3">
                <div className="value-education-card">
                  <figure>
                    <img src="/images/csr/5.jpg" alt="Positive Thinking" />
                  </figure>
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <h4>Positive Thinking</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="education-need csr-new">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pe-lg-5">
              <div className="heading text-start">
                <h2 className="head-1 text-uppercase">
                  NEED FOR VALUE EDUCATION
                </h2>
                <p>
                  Teaching children good values helps them to think from others
                  perspective and help others. It aids them to understand their
                  purpose in life and what good things they need to do for
                  others in society. As the famous saying goes, it is easier to
                  build strong children than to repair broken adults. ISKCON is
                  committed to provide strong values to the next generation
                  using principles from our timeless Vedic scriptures.
                </p>
              </div>
            </div>
            <div className="col-lg-6 ps-xl-5 mt-4 mt-lg-0">
              <figure className="mb-0">
                <img src="/images/csr/NEED-FOR-VALUE-EDUCATION.jpg" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="only-content py-5 csr-new">
        <div className="container">
          <div className="heading text-start">
            <h2 className="head-1 mb-4 text-uppercase">DID YOU KNOW?</h2>
            <ul className="styled-list list-unstyled">
              <li>
                Average attention span of an individual has reduced from 12
                seconds in the year 2000 to just 5 seconds currently.*
              </li>
              <li>15% students aged 15-19 are addicted to alcohol.**</li>
              <li>24.8% of youth are potential addicts to internet.#</li>
            </ul>
            <div className="notes-wrap">
              <small className="fst-normal">*PEW research institute.</small>
              <small className="fst-normal">
                **2012 survey by the health and family welfare ministry.
              </small>
              <a href="http://www.ncbi.nilm.nih.gov/pme/articles/PMC3696236">
                <small className="fst-normal">
                  #http://www.ncbi.nilm.nih.gov/pme/articles/PMC3696236
                </small>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="csr-new what-we-do pb-lg-0 py-0">
        <div className="what-we-do-content-wrap d-none d-lg-block">
          <div className="row w-100 m-0">
            <div
              className="only-content dark col-lg-6"
              style={{ height: "355px" }}
            ></div>
            <div className="only-content col-lg-6"></div>
          </div>

          <div className="what-we-do-content">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-6 h-100 d-flex justify-content-center align-items-center">
                  <div className="what-we-do-card ps-lg-0">
                    <div className="heading text-start">
                      <h2 className="head-1 mb-4 text-uppercase">
                        WHAT WE DO?
                      </h2>
                      <p>
                        ISKCON has designed a curriculum to impart values and
                        principles to children. This has been articulated by a
                        team of PhD's who have expertise in the field of
                        education. We have taken the values imparted in our
                        Vedic teachings and presented them in our value
                        education book in a way that appeals to children of the
                        current generation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 h-100 d-flex justify-content-center align-items-center">
                  <div className="what-we-do-card light  pe-lg-0">
                    <div className="heading text-start">
                      <h2 className="head-1 mb-4 text-uppercase">
                        VALUE EDUCATION CONTEST
                      </h2>
                      <p>
                        ISKCON visits various schools around the country wherein
                        students are enrolled in a value education contest. Each
                        of them get our value education book along with a copy
                        of the Bhagavad-Gita as a reference book. This activity
                        is conducted in the form of a contest held in schools.
                        We have found that this format ensures students go
                        through the book thoroughly, thus helping us achieve our
                        goal of imparting values to them. Prizes are awarded to
                        top 15 participants.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="only-content dark py-5 col-lg-6 d-lg-none">
          <div className="d-flex justify-content-center align-items-center p-3">
            <div className="heading text-start">
              <h2 className="head-1 mb-4 text-uppercase">WHAT WE DO?</h2>
              <p>
                ISKCON has designed a curriculum to impart values and principles to children. This has been articulated by a team of PhD's who have expertise in the field of education. We have taken the values imparted in our Vedic teachings and presented them in our value education book in a way that appeals to children of the current generation.
              </p>
            </div>
          </div>
        </div>
        <div className="only-content py-5 col-lg-6 d-lg-none">
          <div className="d-flex justify-content-center align-items-center p-3">
            <div className="heading text-start">
              <h2 className="head-1 mb-4 text-uppercase">
                VALUE EDUCATION CONTEST
              </h2>
              <p>
                ISKCON visits various schools around the country wherein
                students are enrolled in a value education contest. Each of them
                get our value education book along with a copy of the
                Bhagavad-Gita as a reference book. This activity is conducted in
                the form of a contest held in schools. We have found that this
                format ensures students go through the book thoroughly, thus
                helping us achieve our goal of imparting values to them. Prizes
                are awarded to top 15 participants.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="csr-new csr-curriculum">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-8 mx-auto">
              <div className=" heading dual-heading center">
                <h2 className="text-uppercase head-1">
                  OUR CURRICULUM AIMS{" "}
                  <span className="d-block text-uppercase">
                    AT IMPARTING VALUES SUCH AS
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-lg-4 order-1 order-lg-0">
              <ul className="list-unstyled text-yellow">
                <li className="text-uppercase">
                  <span className="pointer"></span> Respect
                </li>
                <li className="text-uppercase">
                  <span className="pointer"></span> Simplicity
                </li>
                <li className="text-uppercase">
                  <span className="pointer"></span> Forgiveness
                </li>
                <li className="text-uppercase">
                  <span className="pointer"></span> Truthfulness
                </li>
                <li className="text-uppercase">
                  <span className="pointer"></span> Nob-Violence
                </li>
                <li className="text-uppercase">
                  <span className="pointer"></span> Good-Habits
                </li>
                <li className="text-uppercase">
                  <span className="pointer"></span> Self-Control
                </li>
                <li className="text-uppercase">
                  <span className="pointer"></span> Compassion
                </li>
              </ul>
            </div>
            <div className="col-lg-8 order-0 order-lg-1 d-flex justify-content-center align-item-center">
              <figure className="w-100 d-flex justify-content-center align-items-center">
                <img
                  className="w-100"
                  src="/images/csr/img.jpg"
                  alt="our curriculum"
                />
              </figure>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-lg-7 d-flex justify-content-center align-items-center">
              <figure className="w-100">
                <img className="w-100" src="/images/csr/REACH.jpg" alt="" />
              </figure>
            </div>
            <div className="col-lg-5">
              <div className="col-4 w-100 d-lg-flex justify-content-center justify-content-lg-start align-items-center mb-lg-3">
                <h2 className="text-uppercase  text-center reach-title text-yellow pb-3">
                  Reach
                </h2>
              </div>
              <div className="reach-content">
                <div className="row">
                  <div className="col-4 text-center">
                    <h2 className="">10</h2>
                    <h3 className="text-uppercase">years</h3>
                  </div>
                  <div className="col-4 text-center">
                    <h2>180</h2>
                    <h3 className="text-uppercase">district</h3>
                  </div>
                  <div className="col-4 text-center">
                    <h2>2100</h2>
                    <h3 className="text-uppercase">schools</h3>
                  </div>
                </div>
                <div className="text-center pt-4 bottom-count">
                  <h2>8,00,000+</h2>
                  <h3 className="text-uppercase">students benefited</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="csr-new goal-cost ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="goal-card">
                <h4>GOALS FOR OUTREACH</h4>
                <p>
                  In 2023, we plan to reach out to 1,00,000 students across 500
                  schools in Maharashtra, Gujarat, Andhra Pradesh and Telangana.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="goal-card">
                <h4>COST</h4>
                <p>
                  We spend Rs. 300 per student. This includes the value
                  education book and a copy of the Bhagavad Gita as a reference
                  book.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="csr-new testimonial">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="heading ">
                <h2 className="head-1 pt-0">TESTIMONIALS</h2>
              </div>
            </div>
          </div>
          <div className="csr-test-wrap mt-5 d-none d-lg-block">
            <div className="row">
              <div className="col-lg-6 d-flex justify-content-center">
                <div className="testimonial-card">
                  <div className="d-flex justify-content-between align-item-center flex-column">
                    <div className="row">
                      <div className="col-12">
                        <figure className="mb-0 mx-auto">
                          <img src="/images/csr/modi.webp" alt="" />
                        </figure>
                      </div>
                      <div className="col-12 mt-4">
                        <h5 className="text-center">Sri Narendra Modi</h5>
                        <h6 className="text-center">
                          Hon'ble Prime Minister of India
                        </h6>
                        <p className="text-center">
                          The initiative by ISKCON to hold a Value Education
                          Contest is admirable. No education is complete without
                          instilling in the youth the core values of confidence,
                          conviction, and character. Our new education reforms
                          emphasize on character building and aim to channelize
                          the energy of the youth toward nation building and
                          national awakening.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-item-center mt-3">
                      <button
                        className="text-uppercase read-more-btn"
                        onClick={() => {
                          setReadMoreModal1(true);
                        }}
                      >
                        Read More
                      </button>
                      <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={readMoreModal1}
                        onHide={() => setReadMoreModal1(false)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter">
                            Testimonials
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="testimonial-popup-card">
                            <div className="col-12">
                              <figure className="mb-0 mx-auto">
                                <img src="/images/csr/modi.webp" alt="" />
                              </figure>
                            </div>
                            <div className="col-12 mt-4">
                              <h5 className="text-center">
                                Sri Narendra Modi
                              </h5>
                              <h6 className="text-center">
                                Hon'ble Prime Minister of India
                              </h6>
                              <p className="text-center">
                                The initiative by ISKCON to hold a Value
                                Education Contest is admirable. No education is
                                complete without instilling in the youth the
                                core values of confidence, conviction, and
                                character. Our new education reforms emphasize
                                on character building and aim to channelize the
                                energy of the youth toward nation building and
                                national awakening.
                              </p>
                              <p className="text-center">
                                The Society's initiative will further this
                                spirit as the contest will help our youngsters
                                experience the richness of our traditions, as
                                well as imbibe noble ideals and values. Best
                                wishes to all the participants of the Value
                                Education Contest. I am sure that the contest
                                will be a huge success.
                              </p>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center">
                <div className="testimonial-card">
                  <div className="d-flex justify-content-between align-item-center flex-column">
                    <div className="row">
                      <div className="col-12">
                        <figure className="mb-0 mx-auto">
                          <img src="/images/csr/pranab.webp" alt="" />
                        </figure>
                      </div>
                      <div className="col-12 mt-4">
                        <h5 className="text-center">Sri Pranab Mukherjee</h5>
                        <h6 className="text-center">
                          Former President of India
                        </h6>
                        <p className="text-center">
                          ISKCON has over the years played an important role in
                          popularizing the noble and eternal message of the
                          Srimad Bhagavad Gita, promoting spiritual harmony.
                        </p>
                        <p className="text-center">
                          I congratulate ISKCON for its efforts to highlight and
                          propagate the timeless teachings of Lord Krishna
                          across the world.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-item-center mt-3">
                      <button
                        className="text-uppercase read-more-btn"
                        onClick={() => {
                          setReadMoreModal2(true);
                        }}
                      >
                        Read More
                      </button>
                      <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={readMoreModal2}
                        onHide={() => setReadMoreModal2(false)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter">
                            Testimonials
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="testimonial-popup-card">
                            <div className="col-12">
                              <figure className="mb-0 mx-auto">
                                <img src="/images/csr/pranab.webp" alt="" />
                              </figure>
                            </div>
                            <div className="col-12 mt-4">
                              <h5 className="text-center">
                                Sri Pranab Mukherjee
                              </h5>
                              <h6 className="text-center">
                                Former President of India
                              </h6>
                              <p className="text-center">
                                ISKCON has over the years played an important
                                role in popularizing the noble and eternal
                                message of the Srimad Bhagavad Gita, promoting
                                spiritual harmony.
                              </p>
                              <p className="text-center">
                                I congratulate ISKCON for its efforts to
                                highlight and propagate the timeless teachings
                                of Lord Krishna across the world.
                              </p>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="csr-test-wrap mt-5 d-lg-none">
            <Splide
              options={{
                type: "loop",
                rewind: true,
                gap: 40,
                perPage: 2,
                perMove: 1,
                fixWidth: "500px",
                pagination: true,
                arrows: false,
                autoplay: true,
                interval: 4000,
                breakpoints: {
                  1200: {
                    perPage: 1,
                  },
                },
              }}
            >
              <SplideSlide className="testimonial-slide">
                <div className="row">
                  <div className="col-12">
                    <figure className="mb-0 mx-auto">
                      <img src="/images/csr/modi.webp" alt="" />
                    </figure>
                  </div>
                  <div className="col-12 mt-4">
                    <h5 className="text-center">Sri Narendra Modi</h5>
                    <h6 className="text-center">
                      Hon'ble Prime Minister of India
                    </h6>
                    <p className="text-center">
                      The initiative by ISKCON to hold a Value Education Contest
                      is admirable. No education is complete without instilling
                      in the youth the core values of confidence, conviction,
                      and character. Our new education reforms emphasize on
                      character building and aim to channelize the energy of the
                      youth toward nation building and national awakening.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="testimonial-slide">
                <div className="row">
                  <div className="col-12">
                    <figure className="mb-0 mx-auto">
                      <img src="/images/csr/pranab.webp" alt="" />
                    </figure>
                  </div>
                  <div className="col-12 mt-4">
                    <h5 className="text-center">Sri Pranab Mukherjee</h5>
                    <h6 className="text-center">Former President of India</h6>
                    <p className="text-center">
                      ISKCON has over the years played an important role in
                      popularizing the noble and eternal message of the Srimad
                      Bhagavad Gita, promoting spiritual harmony.
                    </p>
                    <p className="text-center">
                      I congratulate ISKCON for its efforts to highlight and
                      propagate the timeless teachings of Lord Krishna across
                      the world.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </section>
      <section className="csr-testimonial _students csr-new">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="heading">
                <h2 className="head-1 pt-0">WHAT STUDENTS SAY</h2>
              </div>
            </div>
          </div>
          <div className="csr-test-wrap mt-5">
            <div className="row">
              <div className="col-lg-6">
                <div className="testimonial-slide static me-lg-3">
                  <div className="row">
                    <div className="col-md-3 col-lg-12 col-xl-3 d-lg-flex justify-content-center d-xl-block">
                    <div className="rounded-img mb-lg-3 mb-xl-0 d-lg-flex justify-content-center d-xl-block" >
                      <figure className="mb-0 _first rounded-img w-lg-75 w-xl-100">
                        <img src="/images/csr/std-1.webp" alt="" />
                      </figure>
                    </div>
                    </div>
                    <div className="col-md-9 text-lg-center text-xl-start col-lg-12 col-xl-9 mt-4 mt-md-0">
                      <p>
                        Some of my good friends who could have done better than
                        me but couldn't perform well because of distractions due
                        to bad habits. | strongly feel, that this contest will
                        help them build a strong character -which is the key to
                        live a happy life.
                      </p>
                      <h5 className="mb-1">Aum Chawre</h5>
                      <h6>9th Std. Lilavatibai Poddar High School</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0">
                <div className="testimonial-slide static ms-lg-3">
                  <div className="row">
                    <div className="col-md-3 col-lg-12 col-xl-3 d-lg-flex justify-content-center d-xl-block">
                      <div className="rounded-img mb-lg-3 mb-xl-0 d-lg-flex justify-content-center d-xl-block">
                        <figure className="mb-0 rounded-img w-lg-75 w-xl-100">
                          <img src="/images/csr/std-2.webp" alt="" />
                        </figure>
                      </div>
                    </div>
                    <div className="col-md-9 text-lg-center text-xl-start col-lg-12 col-xl-9 mt-4 mt-md-0">
                      <p>
                        I really enjoyed learning values ,specially using
                        booklet, from Bhagvad Gita. It made me to think more
                        about self identity and grow in all directions of life.
                      </p>
                      <h5 className="mb-1">Disha Jadhav</h5>
                      <h6>5th Std. Parle Tilak Vidhyalaya ICSE</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="csr-partners cta _query csr-new">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="heading text-start">
                <h2 className="head-1 text-capitalize text-white">
                  For CSR related query, please contact
                </h2>
                <ul className="list-unstyled mt-4">
                  <li className="bullet mb-4">
                    <p className="my-0">
                      <a href="https://imojo.in/Xm60Ai" target="_blank">
                        Nitai Jivan Das{" "}
                      </a>
                      <a href="tel:9022873181"> (+91-9022873181)</a>
                    </p>
                  </li>
                  <li className="bullet">
                    <p className="my-0">
                      Email-ID :{" "}
                      <a href="mailto:nitai.jivana.gkg@iskcon.net">
                        nitai.jivana.gkg@iskcon.net
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CsrNew;
