import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ApiService from '../../api/ApiService';
import Fancybox from '../../helpers/Fancybox';
import { Link, useNavigate } from 'react-router-dom'


const WhyKrishna = () => {

    const navigate = useNavigate()

  const [videos,setVideos]=useState([])
  const [vidCount, setVidCount] = useState(0)
  const fetchVideos=async()=>{
   try{
    const res= await ApiService.fetchHomepageVideo()
    setVideos(res.videos)
    setVidCount(res.videoCount)
    // console.log(res)
   }catch(error){
       console.log(error)
   }
  }
  useEffect(()=>{
    fetchVideos()
  },[])
  return (
    <section className="yt-links-sec">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 mx-auto">
                    <div className="heading">
                        <h2 className="head-1">WHY KRISHNA? </h2>
                        <p className='b-line'>
                            Deep dive into the spiritual oasis that is the Hare Krishna Movement by way of these beautiful short-films of under 90-seconds duration.
                            Discover the bliss of Bhakti...
                        </p>
                    </div>
                </div>
            </div>
            <div className="video-wrap">

                <div className="row my-5">
                    { 
                        videos?.length === 0 ? <>
                           <div className="col-lg-4 col-md-6 mb-5">
                                <Skeleton height={230}/>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <Skeleton height={230}/>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <Skeleton height={230}/>
                            </div>
                        </>:<>
                           {
                               videos?.map((video,i)=>(
                                <div key={i} className="col-md-4 " >
                                    <div className="video-holder">
                                        <Fancybox>
                                            <a data-fancybox="video" href={video?.video} data-thumb={`${process.env.REACT_APP_S3_URL}/uploads/whykrishna/${video?.thumbnailImage}`}  >
                                                
                                                <figure className="mb-0 thumbnil">
                                                    <img src={`${process.env.REACT_APP_S3_URL}/uploads/whykrishna/${video?.thumbnailImage}`} alt=""/>
                                                </figure>
                                                <div className="yt-logo">
                                                    <figure className="yt-figure">
                                                        <img src="/images/homepage/why-krishna/youtube.jpg" alt=""/>
                                                    </figure>
                                                </div>
                                                <div className="reason">
                                                    <p>{video?.title} </p>
                                                </div>
                                            </a>
                                        </Fancybox>
                                    </div>
                                </div>
                            ))
                           }
                        </>  
                    }
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
            {/* <Link className="custom-btn-cls box-hover" to="/movement">MUST WATCH</Link> */}
            <button className="custom-btn-cls box-hover" onClick={() => navigate('/movement', {state:{vidCount}})}>MUST WATCH</button>
                
            </div>
        </div>

    </section>
  )
}

export default WhyKrishna