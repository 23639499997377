import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../components/common/PageHeader'
import MetaData from '../helpers/MetaData'
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import {Tab, Tabs} from 'react-bootstrap'

const CSR = () => {
    const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    if (slider1 && slider2) {
      slider1.current?.sync(slider2.current?.splide);
    }
  }, [slider1, slider2]);

  return (
    <>
        <MetaData title="Corporate Social Responsibility - ISKCON of Attapur" />
        <PageHeader title="ISKCON Attapur CSR" imgSrc="/images/page-header/24.jpg" description="Creating a Better Society for Tomorrow" />
        <section className="upcoming-immersions _csr-page bg-white">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-sm-6 justify-content-center mb-4 mb-lg-0">
                        <Link to="#">
                            <div className="immersion-wrap mb-0">
                                <figure>
                                <img
                                    src="/images/homepage/immersions/upcoming-immersions-1.webp"
                                    alt=""
                                />
                                <div className="immersion-text d-flex justify-content-center flex-column align-items-center">
                                    <h4>ANNADAAN</h4>
                                </div>
                                </figure>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6 justify-content-center mb-4 mb-lg-0">
                        <Link to="#">
                            <div className="immersion-wrap mb-0">
                                <figure>
                                <img
                                    src="/images/homepage/immersions/upcoming-immersions-1.webp"
                                    alt=""
                                />
                                <div className="immersion-text d-flex justify-content-center flex-column align-items-center">
                                    <h4>GREEN INITIATIVES</h4>
                                </div>
                                </figure>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6 justify-content-center">
                        <Link to="#">
                            <div className="immersion-wrap mb-0">
                                <figure>
                                <img
                                    src="/images/homepage/immersions/upcoming-immersions-1.webp"
                                    alt=""
                                />
                                <div className="immersion-text d-flex justify-content-center flex-column align-items-center">
                                    <h4>VALUE EDUCATION</h4>
                                </div>
                                </figure>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
        <section className="csr-annadan">
            <div className="container">
                <div className="row mb-xl-5">
                    <div className="col-lg-8 mx-auto">
                        <div className="heading _main">
                            <h2 className="head-1">ANNADAAN</h2>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-4 mt-4 mt-lg-0 order-1 order-lg-0">
                        <figure className="mb-0">
                            <img src="/images/csr/annadan.webp" alt="" />
                        </figure>
                    </div>
                    <div className="col-lg-8 ps-xl-5 order-0 order-lg-1">
                        <div className="heading text-start">
                            <h4 className='text-uppercase'>Activities</h4>
                            <p className='mt-0'>
                            Annadaan or free food distribution program, is one of our most important activity
                            </p>
                            <ul className='list-unstyled'>
                                <li className='bullet mb-sm-4 mb-2'>
                                    <p className='my-0 ps-2'>
                                       <span style={{color:'white'}} > Daily 1000-1500 meals are being distributed since April’ 2020</span>
                                    </p>
                                </li>
                                <li className='bullet mb-sm-4 mb-2'>
                                    <p className='my-0 ps-2'>
                                        Mega Food Distribution drives on festivals Like Makar Sankranti, Ram Navami, Janmashtami & Diwali.
                                    </p>
                                </li>
                                <li className='bullet'>
                                    <p className='my-0 ps-2'>
                                        More than 6 Lakh meals already distributed in the calendar year 2022.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="csr-wrap">
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="_header">
                                <h4>Below is the glimpse of our Food Distribution activity in last 2 years.</h4>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-8 col-7 pe-0">
                                    <div className="tab-col">
                                        <h5>Period</h5>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-5 ps-0">
                                    <div className="tab-col border-start-0">
                                        <h5>Number of food packets distributed</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-8 col-7 pe-0">
                                    <div className="tab-col">
                                        <p>
                                        April - Dec 2020
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-5 ps-0">
                                    <div className="tab-col border-start-0">
                                        <p>
                                            2,70,000
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-8 col-7 pe-0">
                                    <div className="tab-col">
                                        <p>
                                        Jan - Dec 2021
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-5 ps-0">
                                    <div className="tab-col border-start-0">
                                        <p>
                                            5,60,000
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-8 col-7 pe-0">
                                    <div className="tab-col">
                                        <p>
                                        Jan - Mar 2022
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-5 ps-0">
                                    <div className="tab-col border-start-0">
                                        <p>
                                            1,20,000
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-8 col-7 pe-0">
                                    <div className="tab-col">
                                        <p>
                                        April - Jun 2022 (50K meals on Ram Navami)
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-5 ps-0">
                                    <div className="tab-col border-start-0">
                                        <p>
                                            1,50,000
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-8 col-7 pe-0">
                                    <div className="tab-col">
                                        <p>
                                        Jul - Sep 2022 (1 lakh meals on Janmashtami)
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-5 ps-0">
                                    <div className="tab-col border-start-0">
                                        <p>
                                            2,30,000
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-8 col-7 pe-0">
                                    <div className="tab-col">
                                        <p>
                                        Projected Q4'22 (50K meals on Diwali)
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-5 ps-0">
                                    <div className="tab-col border-start-0">
                                        <p>
                                            2,00,000
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="future-plan campaign-main-sec border-0">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-6">
                        <div className="heading text-start">
                            <h2 className='head-1'>
                            OUR FUTURE PLAN
                            </h2>
                            <p>
                            Given that food distribution is the most important activity for ISKCON Attapur, in 2023, we plan to distribute over 10 lakh free meals to the needy. In 2024, we will work towards increasing the distribution to 12 lakh meals.
                            </p>
                            <p>
                            Attapur has over 10 lakh daily wage earners and a sizeable portion of the city population is below the poverty line. When people living below the poverty line, even though they may be daily wage earners, are guaranteed one hot meal for all members of their family, that is one less expense for them, which automatically leads to savings that can be used towards necessities such as medical, education etc. Thus, our efforts are factually leading to a better standard of life for the underprivileged.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="story-slider">
                            <div className="image-preview mt-4 mt-lg-0">
                                <Splide  ref={slider2} options={{
                                    type: "fade",
                                    rewind: true,
                                    pagination: false,
                                    arrows: false,
                                }}>
                                    <SplideSlide className="image-preview-slide">
                                        <figure className='mb-0'>
                                            <img src="/images/csr/annadan.webp" alt="" />
                                        </figure>
                                    </SplideSlide>
                                    <SplideSlide className="image-preview-slide">
                                        <figure className='mb-0'>
                                            <img src="/images/csr/future-plan-1.webp" alt="" />
                                        </figure>
                                    </SplideSlide>
                                    <SplideSlide className="image-preview-slide">
                                        <figure className='mb-0'>
                                            <img src="/images/csr/future-plan-2.webp" alt="" />
                                        </figure>
                                    </SplideSlide>
                                </Splide>
                            </div>
                            <div className="thumbnail-slider-wrap mb-5 mb-lg-0">
                                <Splide  ref={slider1} options={{
                                    gap: 10,
                                    pagination: false,
                                    cover: true,
                                    arrows: false,
                                    perPage: 3,
                                    height: 120,
                                    heightRatio: 0.5,
                                    autoplay: true,
                                    speed: 1000,
                                    interval: 5000,
                                    perMove: 1,
                                    type: "loop",
                                }}>
                                    <SplideSlide className="thumbnail-slide">
                                        <figure className='mb-0'>
                                            <img src="/images/csr/annadan.webp" alt="" />
                                        </figure>
                                    </SplideSlide>
                                    <SplideSlide className="thumbnail-slide">
                                        <figure className='mb-0'>
                                            <img src="/images/csr/future-plan-1.webp" alt="" />
                                        </figure>
                                    </SplideSlide>
                                    <SplideSlide className="thumbnail-slide">
                                        <figure className='mb-0'>
                                            <img src="/images/csr/future-plan-2.webp" alt="" />
                                        </figure>
                                    </SplideSlide>
                                </Splide>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="csr-partners cta">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="heading text-start">
                            <h2 className="head-1">
                            PARTNER WITH US
                            </h2>
                            <ul className="list-unstyled mt-4">
                                <li className="bullet mb-4">
                                    <p className='my-0'>
                                    Support the Daily Food Distribution activity, one meal costs Rs 30/-
                                    </p>
                                </li>
                                <li className="bullet mb-4">
                                    <p className='my-0'>
                                    Sponsor Food Distribution Vehicle.
                                    </p>
                                </li>
                                <li className="bullet mb-4">
                                    <p className='my-0'>
                                    Sponsor Kitchen Equipment's.
                                    </p>
                                </li>
                                <li className="bullet">
                                    <p className='my-0'>
                                    Sponsor Installation of Solar Panels to generate green energy.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="csr-partners-slider">
                            <Splide
                                options={{
                                type: "loop",
                                rewind: true,
                                pagination: false,
                                arrows: false,
                                perPage: 6,
                                gap: 30,
                                drag: "free",
                                breakpoints: {
                                    576: {
                                        perPage: 2
                                    },
                                    1200: {
                                        perPage: 5
                                    },
                                    991: {
                                        perPage: 4
                                    }
                                },
                                autoScroll: {
                                    pauseOnHover: false,
                                    pauseOnFocus: false,
                                    rewind: false,
                                    speed: 1
                                  }
                                }}
                                extensions={{ AutoScroll }}
                            >
                                <SplideSlide className="partner-slide">
                                    <figure className="mb-0">
                                        <img src="/images/csr/partner-1.webp" alt="" />
                                    </figure>
                                </SplideSlide>
                                <SplideSlide className="partner-slide">
                                    <figure className="mb-0">
                                        <img src="/images/csr/partner-2.webp" alt="" />
                                    </figure>
                                </SplideSlide>
                                <SplideSlide className="partner-slide">
                                    <figure className="mb-0">
                                        <img src="/images/csr/partner-3.webp" alt="" />
                                    </figure>
                                </SplideSlide>
                                <SplideSlide className="partner-slide">
                                    <figure className="mb-0">
                                        <img src="/images/csr/partner-4.webp" alt="" />
                                    </figure>
                                </SplideSlide>
                                <SplideSlide className="partner-slide">
                                    <figure className="mb-0">
                                        <img src="/images/csr/partner-5.webp" alt="" />
                                    </figure>
                                </SplideSlide>
                                <SplideSlide className="partner-slide">
                                    <figure className="mb-0">
                                        <img src="/images/csr/partner-6.webp" alt="" />
                                    </figure>
                                </SplideSlide>
                                <SplideSlide className="partner-slide">
                                    <figure className="mb-0">
                                        <img src="/images/csr/partner-7.webp" alt="" />
                                    </figure>
                                </SplideSlide>
                                <SplideSlide className="partner-slide">
                                    <figure className="mb-0">
                                        <img src="/images/csr/partner-8.webp" alt="" />
                                    </figure>
                                </SplideSlide>
                                <SplideSlide className="partner-slide">
                                    <figure className="mb-0">
                                        <img src="/images/csr/partner-9.webp" alt="" />
                                    </figure>
                                </SplideSlide>
                                <SplideSlide className="partner-slide">
                                    <figure className="mb-0">
                                        <img src="/images/csr/partner-10.webp" alt="" />
                                    </figure>
                                </SplideSlide>
                                <SplideSlide className="partner-slide">
                                    <figure className="mb-0">
                                        <img src="/images/csr/partner-11.webp" alt="" />
                                    </figure>
                                </SplideSlide>
                            </Splide>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='green-init'>
            <div className="container">
                <div className="row mb-xl-5 mb-4">
                    <div className="col-lg-8 mx-auto">
                        <div className="heading _main">
                            <h2 className="head-1 text-uppercase">Green Initiatives</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mx-auto">
                        <div className="heading">
                            <h4>
                            ABOUT RAJANNA SIRCILLA
                            </h4>
                            <p>
                            Rajanna Sircilla is a town and district located on the banks of Maneru river in the Sircilla mandal, about 180 kms from Hyderabad city.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="green-init-tab-wrap mt-md-5 mt-4">
                    <Tabs
                        defaultActiveKey="about"
                        id="green-init"
                        className='green-init-tabs'
                        
                    >
                        <Tab eventKey="about" title="ABOUT ISKCON SIRCILLA">
                           <div className="row align-items-center">
                            <div className="col-lg-4 order-1 order-lg-0">
                                <figure className="mb-0 _first">
                                    <img src="/images/csr/green-init-1.webp" alt="" />
                                </figure>
                            </div>
                            <div className="col-lg-8 ps-xl-5 order-0 order-lg-1">
                                <div className="tab-inner-content">
                                    <h4>ABOUT ISKCON SIRCILLA</h4>
                                    <p>
                                    ISKCON Bhiwandi, acquired 16.89 acres of farm land in the city of Sircilla with the aim of having a closer relationship with mother nature by cultivating organic farming.
                                    </p>
                                    <p>
                                    70% of our land has been earmarked for green initiatives such as plantation of trees and plants, including vegetable, fruit and flower bearing ones. Water conservation measures will also be taken to ensure optimum green efficiency.
                                    </p>
                                </div>
                            </div>
                           </div>
                           <div className="row align-items-center mt-lg-4 mt-5 mt-xl-0">
                            <div className="col-lg-7">
                                <div className="tab-inner-content">
                                    <p>
                                    A portion of the land will be used for a goshala, where cows will be nurtured and protected. This will aid our objective of full organic farming, as the waste of the cows will be used as manure for the land.
                                    </p>
                                    <p>
                                    We will also conduct farmer training programs and livestock protection programs for the benefit of the local farmers of the district. Apart from these core activities, and in line with one of ISKCON's core principles, we will be serving out meals to the needy section of the local population as part of our Food for Life program.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <figure className="mb-0 _first">
                                    <img src="/images/csr/green-init-2.webp" alt="" />
                                </figure>
                            </div>
                           </div>
                        </Tab>
                        <Tab eventKey="plans" title="OUR PLANS">
                        <div className="row align-items-center">
                            <div className="col-lg-4 order-1 order-lg-0 mt-4 mt-lg-0">
                                <figure className="mb-0">
                                    <img src="/images/csr/green-init-3.webp" alt="" />
                                </figure>
                            </div>
                            <div className="col-lg-8 order-0 order-lg-1 ps-xl-5">
                                <div className="tab-inner-content">
                                    <h4>OUR PLANS</h4>
                                    <p>
                                    A boundary fence has been erected to safeguard the entire property. 7.5 acres of land has been levelled to make it ready for plantation.
                                    </p>
                                    <p>
                                    In phase one, the plantation of trees on 5.5 acres has begun. Plantation on another 2 acres will begin in December 2022.
                                    </p>
                                    <p>
                                    In phase two, the remaining land will be leveled and plantation will be done on 4.5 acres of land. We will also build a Goshala and a facility for conducting training programs.
                                    </p>
                                    <p>
                                    We aim to plant more than 2000 trees which will cover all three major categories i.e. flowers, fruits and vegetables.
                                    </p>
                                </div>
                            </div>
                        </div>
                        </Tab>
                        <Tab eventKey="partner" title="ABOUT ISKCON SIRCILLA">
                           <div className="row align-items-center">
                            <div className="col-lg-4 order-1 order-lg-0">
                                <figure className="mb-0 _first">
                                    <img src="/images/csr/green-init-5.webp" alt="" />
                                </figure>
                            </div>
                            <div className="col-lg-8 ps-xl-5 order-0 order-lg-1">
                                <div className="tab-inner-content">
                                    <h4>PARTNER WITH US</h4>
                                    <p>
                                    Support the 1st phase of tree plantation activity at ISKCON Sircilla.
                                    </p>
                                    <p>
                                    Support the procurement of capital assets which will assist the farm with plantation, organic farming and live stock development.
                                    <ul className='list-unstyled'>
                                        <li className='bullet'>
                                        Sponsor a tractor
                                        </li>
                                        <li className='bullet'>
                                        Sponsor a pick-up truck
                                        </li>
                                        <li className='bullet'>
                                        Sponsor installation of solar panels to generate green energy and have a natural source of electricity supply at the farm
                                        </li>
                                        <li className='bullet'>
                                        Sponsor Water Tankers
                                        </li>
                                    </ul>
                                    </p>
                                    <p>
                                    Support the 2nd phase of tree plantation activity which includes further levelling of the soil and installing a water dripping system along with irrigation pipes.
                                    </p>
                                </div>
                            </div>
                           </div>
                          
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </section>
        <section className='value-education yt-links-sec'>
            <div className="container">
                <div className="row mb-4">
                    <div className="col-lg-8 mx-auto">
                        <div className="heading _main text-center">
                            <h2 className="head-1 text-uppercase">
                            Value Education
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="video-wrap">
                    <div className="row justify-content-center">
                        <div className="col-12 mb-4">
                            <div className="heading text-center">
                                <h4>EMPOWERING INDIA WITH VALUES</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="video-holder _csr-edu">
                                <a>
                                    <figure className="mb-0 thumbnil">
                                        <img src="/images/csr/edu-1.jpg" alt="" />
                                    </figure>
                                    <div className="reason">
                                        <p>
                                            CONCENTRATION
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="video-holder _csr-edu">
                                <a>
                                    <figure className="mb-0 thumbnil">
                                        <img src="/images/csr/edu-1.jpg" alt="" />
                                    </figure>
                                    <div className="reason">
                                        <p>
                                            TRUTHFULNESS
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="video-holder _csr-edu">
                                <a>
                                    <figure className="mb-0 thumbnil">
                                        <img src="/images/csr/edu-1.jpg" alt="" />
                                    </figure>
                                    <div className="reason">
                                        <p>
                                            DISCIPLINE
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="video-holder _csr-edu">
                                <a>
                                    <figure className="mb-0 thumbnil">
                                        <img src="/images/csr/edu-1.jpg" alt="" />
                                    </figure>
                                    <div className="reason">
                                        <p>
                                            DUTIFUL
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="video-holder _csr-edu">
                                <a>
                                    <figure className="mb-0 thumbnil">
                                        <img src="/images/csr/edu-1.jpg" alt="" />
                                    </figure>
                                    <div className="reason">
                                        <p>
                                            POSITIVE THINKING
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='education-need'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8">
                        <div className="heading text-start">
                            <h2 className="head-1">
                            <span  style={{color:'black'}}>NEED FOR VALUE EDUCATION</span>
                            </h2>
                            <p>
                            Teaching children good values helps them to think from others perspective and help others. It aids them to understand their purpose in life and what good things they need to do for others in society. As the famous saying goes, it is easier to build strong children than to repair broken adults. ISKCON is committed to provide strong values to the next generation using principles from our timeless Vedic scriptures.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 ps-xl-5 mt-4 mt-lg-0">
                        <figure className="mb-0">
                            <img src="/images/csr/edu-need.webp" alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
        <section className='only-content py-5'>
            <div className="container">
                <div className="heading text-start">
                    <h2 className="head-1 mb-4">
                    DID YOU KNOW?
                    </h2>
                    <ul className="list-unstyled">
                        <li className="bullet">
                        Average attention span of an individual has reduced from 12 seconds in the year 2000 to just 5 seconds currently.*
                        </li>
                        <li className="bullet">
                        15% students aged 15-19 are addicted to alcohol.**
                        </li>
                        <li className="bullet">
                        24.8% of youth are potential addicts to internet.#
                        </li>
                    </ul>
                    <div className="notes-wrap">
                        <small>*PEW research institute.</small>
                        <small>**2012 survey by the health and family welfare ministry.</small>
                        <small>#http://www.ncbi.nilm.nih.gov/pme/articles/ PMC3696236</small>
                    </div>
                </div>
            </div>
        </section>
        <section className='only-content dark py-5'>
            <div className="container bg-light">
                <div className="heading text-start">
                    <h2 className="head-1 mb-4">
                    WHAT WE DO?
                    </h2>
                  <p>
                  ISKCON has designed a curriculum to impart values and principles to children. This has been articulated by a team of PhD's who have expertise in the field of education. We have taken the values imparted in our Vedic teachings and presented them in a our value education book in a way that appeals to children of the current generation.
                  </p>
                </div>
            </div>
        </section>
        <section className='only-content py-5'>
            <div className="container">
                <div className="heading text-start">
                    <h2 className="head-1 mb-4">
                    VALUE EDUCATION CONTEST
                    </h2>
                  <p>
                  ISKCON visits various schools around the country wherein students are enrolled in a value education contest. Each of them get our value education book along with a copy of the Bhagavad-Gita as a reference book. This activity is conducted in the form of a contest held in schools. We have found that this format ensures students go through the book thoroughly, thus helping us achieve our goal of imparting values to them. Prizes are awarded to top 15 participants.
                  </p>
                </div>
            </div>
        </section>
        <section className="curriculum">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-lg-8 mx-auto">
                        <div className="heading">
                            <h2 className="head-1 text-white">
                            OUR CURRICULUM AIMS AT IMPARTING VALUES SUCH AS
                            </h2>
                            <p className='text-white'>
                            Our curriculum is designed to instill valuable traits in students, focusing on character building and overall development. Our curriculum focuses on instilling values such as
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div className="curriculum-card">
                            <div className="icon">
                                <figure className="mb-0">
                                    <img src="/images/csr/feather-black.svg" alt="" />
                                </figure>
                            </div>
                            <div className="content">
                                <h4>Respect</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div className="curriculum-card">
                            <div className="icon">
                                <figure className="mb-0">
                                    <img src="/images/csr/feather-black.svg" alt="" />
                                </figure>
                            </div>
                            <div className="content">
                                <h4>Simplicity</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div className="curriculum-card">
                            <div className="icon">
                                <figure className="mb-0">
                                    <img src="/images/csr/feather-black.svg" alt="" />
                                </figure>
                            </div>
                            <div className="content">
                                <h4>Forgiveness</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div className="curriculum-card">
                            <div className="icon">
                                <figure className="mb-0">
                                    <img src="/images/csr/feather-black.svg" alt="" />
                                </figure>
                            </div>
                            <div className="content">
                                <h4>Truthfulness</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div className="curriculum-card">
                            <div className="icon">
                                <figure className="mb-0">
                                    <img src="/images/csr/feather-black.svg" alt="" />
                                </figure>
                            </div>
                            <div className="content">
                                <h4>Nob-Violence</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div className="curriculum-card">
                            <div className="icon">
                                <figure className="mb-0">
                                    <img src="/images/csr/feather-black.svg" alt="" />
                                </figure>
                            </div>
                            <div className="content">
                                <h4>Good-Habits</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div className="curriculum-card">
                            <div className="icon">
                                <figure className="mb-0">
                                    <img src="/images/csr/feather-black.svg" alt="" />
                                </figure>
                            </div>
                            <div className="content">
                                <h4>Self-Control</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div className="curriculum-card mb-0">
                            <div className="icon">
                                <figure className="mb-0">
                                    <img src="/images/csr/feather-black.svg" alt="" />
                                </figure>
                            </div>
                            <div className="content">
                                <h4>Compassion</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='reach pb-4'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-7 col-8">
                        <div className="reach-content">
                            <div className="first">
                                <h4>10</h4>
                                <p>Years</p>
                            </div>
                            <div className="second">
                                <h4>180</h4>
                                <p>District</p>
                            </div>
                            <div className="third">
                                <h4>2100</h4>
                                <p>Schools</p>
                            </div>
                            <div className="last">
                                <h4>8,00,000 +</h4>
                                <p>Students Benefited</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='goal-cost '>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                        <div className="goal-card">
                            <h4>GOALS FOR OUTREACH</h4>
                            <p>
                            In 2023, we plan to reach out to 1,00,000 students across 500 schools in Maharashtra, Gujarat, Andhra Pradesh and Telangana.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="goal-card">
                        <h4>COST</h4>
                        <p>
                        We spend Rs. 300 per student. This includes the value education book and a copy of the Bhagavad Gita as a reference book.
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="csr-testimonial">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <div className="heading _main">
                            <h2 className="head-1 pt-0">
                                TESTIMONIALS
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="csr-test-wrap mt-5">
                    <Splide options={{
                        type: "loop",
                        rewind: true,
                        gap: 20,
                        perPage: 2,
                        perMove: 1,
                        pagination: true,
                        arrows: false,
                        autoplay: true,
                        interval: 4000,
                        breakpoints: {
                            1200: {
                                perPage: 1
                            }
                        }
                    }}>
                        <SplideSlide className='testimonial-slide'>
                            <div className="row">
                                <div className="col-12">
                                    <figure className="mb-0">
                                        <img src="/images/csr/modi.webp" alt="" />
                                    </figure>
                                </div>
                                <div className="col-12 mt-4">
                                    <h5>Sri Narendra Modi, Hon'ble Prime Minister of India</h5>
                                    <p>
                                    The initiative by ISKCON to hold a Value Education Contest is admirable. No education is complete without instilling in the youth the core values of confidence, conviction, and character. Our new education reforms emphasize on character building and aim to channelize the energy of the youth toward nation building and national awakening.
                                    </p>
                                    <p>
                                    The Society's initiative will further this spirit as the contest will help our youngsters experience the richness of our traditions, as well as imbibe noble ideals and values. Best wishes to all the participants of the Value Education Contest. I am sure that the contest will be a huge success.
                                    </p>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide className='testimonial-slide'>
                            <div className="row">
                                <div className="col-12">
                                    <figure className="mb-0">
                                        <img src="/images/csr/pranab.webp" alt="" />
                                    </figure>
                                </div>
                                <div className="col-12 mt-4">
                                    <h5>Sri Pranab Mukherjee, Former President of India</h5>
                                    <p>
                                    ISKCON has over the years played an important role in popularizing the noble and eternal message of the Srimad Bhagavad Gita, promoting spiritual harmony.
                                    </p>
                                    <p>
                                    I congratulate ISKCON for its efforts to highlight and propagate the timeless teachings of Lord Krishna across the world.
                                    </p>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide className='testimonial-slide'>
                            <div className="row">
                                <div className="col-12">
                                    <figure className="mb-0">
                                        <img src="/images/csr/modi.webp" alt="" />
                                    </figure>
                                </div>
                                <div className="col-12 mt-4">
                                    <h5>Sri Narendra Modi, Hon'ble Prime Minister of India</h5>
                                    <p>
                                    The initiative by ISKCON to hold a Value Education Contest is admirable. No education is complete without instilling in the youth the core values of confidence, conviction, and character. Our new education reforms emphasize on character building and aim to channelize the energy of the youth toward nation building and national awakening.
                                    </p>
                                    <p>
                                    The Society's initiative will further this spirit as the contest will help our youngsters experience the richness of our traditions, as well as imbibe noble ideals and values. Best wishes to all the participants of the Value Education Contest. I am sure that the contest will be a huge success.
                                    </p>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide className='testimonial-slide'>
                            <div className="row">
                                <div className="col-12">
                                    <figure className="mb-0">
                                        <img src="/images/csr/pranab.webp" alt="" />
                                    </figure>
                                </div>
                                <div className="col-12 mt-4">
                                    <h5>Sri Pranab Mukherjee, Former President of India</h5>
                                    <p>
                                    ISKCON has over the years played an important role in popularizing the noble and eternal message of the Srimad Bhagavad Gita, promoting spiritual harmony.
                                    </p>
                                    <p>
                                    I congratulate ISKCON for its efforts to highlight and propagate the timeless teachings of Lord Krishna across the world.
                                    </p>
                                </div>
                            </div>
                        </SplideSlide>
                    </Splide>
                </div>
            </div>
        </section>
        <section className="csr-testimonial _students">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <div className="heading _main">
                            <h2 className="head-1 pt-0">
                            WHAT STUDENTS SAY
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="csr-test-wrap mt-5">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="testimonial-slide static">
                            <div className="row">
                                <div className="col-md-3">
                                    <figure className="mb-0 _first">
                                        <img src="/images/csr/std-1.webp" alt="" />
                                    </figure>
                                </div>
                                <div className="col-md-9 mt-4 mt-md-0">
                                    <p>
                                    Some of my good friends who could have done better than me but couldn't perform well because of distractions due to bad habits. | strongly feel, that this contest will help them build a strong character -which is the key to live a happy life.
                                    </p>
                                    <h5 className='mb-1'>Aum Chawre</h5>
                                    <h6>9th Std. Lilavatibai Poddar High School</h6>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            <div className="testimonial-slide static">
                            <div className="row">
                                <div className="col-md-3">
                                    <figure className="mb-0">
                                        <img src="/images/csr/std-2.webp" alt="" />
                                    </figure>
                                </div>
                                <div className="col-md-9 mt-4 mt-md-0">
                                    <p>
                                    I really enjoyed learning values ,specially using booklet, from Bhagvad Gita. It made me to think more about self identity and grow in all directions of life.
                                    </p>
                                    <h5 className='mb-1'>Disha Jadhav</h5>
                                    <h6>5th Std. Parle Tilak Vidhyalaya ICSE</h6>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="csr-partners cta _query">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="heading text-start">
                            <h2 className="head-1 text-capitalize text-white">
                            For CSR related query, please contact yyy
                            </h2>
                            <ul className="list-unstyled mt-4">
                                <li className="bullet mb-4">
                                    <p className='my-0'>
                                    <a href="https://imojo.in/Xm60Ai" target="_blank">Nitai Jivan Das </a>
                                    <a href="tel:9022873181"> (+91-9022873181)</a>
                                    </p>
                                </li>
                                <li className="bullet">
                                    <p className='my-0'>
                                    Email-ID : <a href="mailto:nitai.jivana.gkg@iskcon.net">nitai.jivana.gkg@iskcon.net</a>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default CSR