import PageHeader from "../components/common/PageHeader";
import { useEffect, useRef, useState } from "react";
import GoToTop from "../helpers/GoToTop";
import {
    Link,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import ApiService from "../api/ApiService";
import { useForm, Controller } from "react-hook-form";
import notification from "../helpers/notification";
import MetaData from "../helpers/MetaData";
import { BsCheckLg } from "react-icons/bs";
import Fancybox from "../helpers/Fancybox";
import Skeleton from "react-loading-skeleton";
import { BsFillPlayCircleFill } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { useCallback } from "react";
import axios from "axios";
import moment from "moment/moment";
import Select from 'react-select';
import Tooltip from '@mui/material/Tooltip';

const expiry = process.env.REACT_APP_OTPEXPIRY;
let timer = null;

const Repayment = () => {

    const scrollToElement = () => {
        const element = document.getElementById('scrollToDonateFrom');
        // console.log("elements",element);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    };

    // const formRef = useRef();
    const navigate = useNavigate();
    const { id } = useParams();
    const { setToken, user, token } = useContext(AppContext);

    const search = useLocation().search;
    const donationId = new URLSearchParams(search).get("donationId");

    const [donationTypes, setDonationTypes] = useState([null]);
    const [popularDonation, setPopularDonations] = useState([]);
    const [donationType, setDonationType] = useState(null);
    const [donationImg, setDonationImg] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [categories, setCategories] = useState([]);
    const [type, setType] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isRetry, setIsRetry] = useState(false);
    const [retryDonationDetails, setRetryDonationDetails] = useState(null);
    const [showMemoryBox, setShowMemoryBox] = useState(false);
    const [goseva, setGoseva] = useState(0);
    const [bhagavadGitaPrice, setBhagavadGitaPrice] = useState(0);
    const [isGoseva, setIsGoseva] = useState(false);
    const [isSadhuBhojanSeva, setIsSadhuBhojanSeva] = useState(false);
    const [isBhagvadGita, setIsBhagvadGita] = useState(false);
    const [top, setTop] = useState(0);
    const [showOtp, setShowOtp] = useState(false);
    const [isVerified, setIsverified] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpExp, setOtpExp] = useState(expiry);
    const [showresendlink, setShowResendLink] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0)
    const [icsdontype, setIcsDontype] = useState('A General Donation');
    const [ics_data, setICSdata] = useState()
    const [ics_address, setIcsAddress] = useState([])
    const [ics_pincode, setIcsPincode] = useState([])
    const [icsdatawl, setICSdatawl] = useState([])
    const [item_data, setItemData] = useState([])
    const [wl_loginics, setWithlogin] = useState([])
    const [wl_icsname, setIcsname] = useState("")


    const [scheme_list, setICSscheme] = useState([])

    //console.log(item_data);
    // if(slug){
    //   setTimeout(() => {
    //     const scrollToDonateFromRef = document.getElementById('scrollToDonationForm');
    //     if (scrollToDonateFromRef) {
    //       const offset = -600;
    //       const targetScrollTop = scrollToDonateFromRef.offsetTop - offset;
    //       const container = document.documentElement;
    //       container.scrollTop = targetScrollTop;
    //     } else {
    //       console.error("Element scrollToDonateFromRef not found!");
    //     }
    //   }, 2000);
    // }

    const [recieptId, setRecieptId] = useState("")

    const currentDate = new Date();
    const formattedDate = currentDate.toDateString();

    const Soa = localStorage.getItem('SpecialOccassionAmount');

    const msp = localStorage.getItem('multiplesevaPrice');

    const msn = localStorage.getItem('multiplesevaName');



    const msp_value = !Soa == '' ? msp.split(",") : '';
    const msp_value_a = msp_value[0];
    const msp_value_b = msp_value[1];
    const msp_value_c = msp_value[2];
    const msp_value_d = msp_value[3];
    const msp_value_e = msp_value[4];
    const msp_value_f = msp_value[5];
    const msp_value_g = msp_value[6];
    const msp_value_h = msp_value[7];


    const msn_value = !Soa == '' ? msn.split(",") : '';
    const msn_value_a = msn_value[0];
    const msn_value_b = msn_value[1];
    const msn_value_c = msn_value[2];
    const msn_value_d = msn_value[3];
    const msn_value_e = msn_value[4];
    const msn_value_f = msn_value[5];
    const msn_value_g = msn_value[6];
    const msn_value_h = msn_value[7];

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
        setValue,
        getValues,
        control,
    } = useForm({
        defaultValues: {
            state: "",
            city: "",
        },
    });

    const watchSomeoneMemory = watch(["memoryOfSomeone", "amount", "sadhuBhojanSeva", "bhagavadGita", "goseva"]);

    const fetchStateCity = async (pin) => {
        try {
            let url = `https://api.postalpincode.in/pincode/${pin}`;

            let config = {
                method: "GET",
                url,
            };
            const { data } = await axios(config);

            let cityVal = '';
            if (data[0].PostOffice.length > 1) {
                data[0].PostOffice.map(ct => {
                    if (ct.Block !== 'NA') {
                        return cityVal = ct.Block
                    }
                })
            } else {
                cityVal = data[0].PostOffice[0].Block;
            }

            if (data[0].Status === "Success") {
                setValue("city", cityVal !== '' ? cityVal : data[0].PostOffice[0].District);
                setValue("state", data[0].PostOffice[0].State);
            } else {
                notification("warning", "Enter valid PIN code!");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const formRef = useCallback((node) => {
        if (node) {
            setTop(node.offsetTop);
        }
    });
    useEffect(() => {

        scrollToElement();
    }, [])

    useEffect(() => {
        const showBox = watch((value) => {
            const { amount, sadhuBhojanSeva, bhagavadGita, goseva } = value;
            // console.log(value)
            let total = (amount ? Number(amount) : 0) + (sadhuBhojanSeva ? Number(sadhuBhojanSeva) : 0) + (bhagavadGita ? Number(bhagavadGita) * 200 : 0) + (goseva ? (Number(goseva) * 120) : 0);
            setTotalAmount(total)

            setShowMemoryBox(value.memoryOfSomeone)
        });
        return () => showBox.unsubscribe();
    }, [watch]);

    const recaptchaRef = useRef();

    const fetchDonationType = async () => {
        try {
            const res = await ApiService.fetchDonationService(id);
            // console.log(res)
            setDonationType(res.donationType);
            setIsGoseva(res.donationType.goseva);
            setIsSadhuBhojanSeva(res.donationType.sadhuBhojanSeva);
            setIsBhagvadGita(res.donationType.bhagvadGita);
            if (res.donationType.popular) {
                setPopularDonations(res.donationTypes);
            }
        } catch (error) {
            console.log(error);
        }
    };



    const fetchPopularDonations = async () => {
        try {
            const res = await ApiService.fetchPopularDonations();
            // console.log(res)
            setPopularDonations(res);
            if (!id) {
                setDonationImg(res[0].image);
                setVideoUrl(res[0].videoUrl);
                setIsGoseva(res[0].goseva);
                setIsSadhuBhojanSeva(res[0].sadhuBhojanSeva);
                setIsBhagvadGita(res[0].bhagvadGita);

                let details = {};
                if (!isRetry) {
                    details.donationType = res[0]?._id;
                }

                if (user && !isRetry) {
                    details.name = user.name;
                    details.phone = user.phone;
                    details.email = user.email;
                    details.pan = user.pan;
                    details.address = (user.streetName && user.areaName) ? `${user.streetName}, ${user.areaName}` : user.address;
                    details.pin = user.pin;
                    details.state = user?.state;
                    details.city = user?.city;
                }
                reset(details);
                if (user?.pin) {
                    fetchStateCity(user?.pin)
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if (id) {
            fetchPopularDonations();
        } else {
            // formRef.current.scrollIntoView({ behavior: "smooth" });
            fetchDonationType();
        }
        if (user) {
            setIsverified(true);
        }
    }, [isRetry, donationId, user]);

    useEffect(() => {
        if (donationType?.popular) {
            let details = { donationType: donationType?._id };

            if (user?.pin) {
                fetchStateCity(user?.pin)
            }
        } else {
            // let details = {
            //     donationType: donationType?.title
            //         ? donationType?.title.split("|")[0] +
            //         " " +
            //         (donationType?.title?.split("|").length !== 1
            //             ? donationType?.title?.split("|")[1]
            //             : "")
            //         : "",
            //     amount: donationType?.amount,
            // };


            if (user?.pin) {
                fetchStateCity(user?.pin)
            }
        }
        setDonationImg(donationType?.image);
        setVideoUrl(donationType?.videoUrl);
    }, [donationType, user, isRetry]);

    function getTime(time) {
        return (
            Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
        );
    }

    const handleSendOtp = async (phone) => {
        if (timer) {
            clearInterval(timer);
        }
        if (phone.length >= 10) {
            try {
                const res = await ApiService.sendOtp({ number: phone });
                notification("success", res.message);
                setShowTimer(true);
                timer = setInterval(() => {
                    setOtpExp((prev) => prev - 1);
                }, 1000);
            } catch (err) {
                console.log(err);
                notification("error", err.response.data.message);
            }
        }
    };

    //console.log(item_data)
    //console.log(icsdatawl)
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        return `${year}-${month}-${day}`;
    };


    const handleVerifyOtp = async (otp) => {
        try {
            let otpData = {
                number: getValues("phone"),
                otp,
            };
            const res = await ApiService.loginUser(otpData);
            // console.log(res);
            localStorage.setItem(`token`, res.token);
            setToken(res.token);
            notification("success", res.message);
            setOtp("");
            setIsverified(true);
            setShowOtp(false);
            madeDonation(getValues())

        } catch (err) {
            console.log(err);
            notification("error", err.response.data.message);
        }
    };

    let idd = '';
    const ics_id = localStorage.getItem('ics_id');


    let ics_idd = ""

    if (token) {
        if (item_data.id) {
            ics_idd = item_data.id;
        } else {
            ics_idd = wl_loginics;
        }

    } else {
        if (item_data.id) {
            ics_idd = item_data.id
        } else {
            ics_idd = ics_id;
        }



    }

    // console.log(ics_idd)




    const madeDonation = async (data) => {
        console.log("madeDonation", data);
        try {
            const token = await recaptchaRef.current.executeAsync();
            //const ics_id = localStorage.getItem("ics_id");
            data.token = token;

            data.name = wl_icsname ? wl_icsname : data.name;

            data.userType = user ? "user" : "guest";

            data.due = 'yes';
            data.sub_id = id;

            let _doonationType;
            if (isRetry) {
                _doonationType = retryDonationDetails.donationType;
            } else {
                _doonationType =
                    popularDonation.length > 0 || donationType.popular
                        ? data.donationType
                        : donationType?._id;
            }

            const res = await ApiService.subscribeNityaSeva({
                ...data,
                type,
                donationType: _doonationType,
            });


            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY,
                currency: res.currency,
                amount: res.amount,
                name: process.env.REACT_APP_PROJECT_NAME,
                description: "Help Us to Help the Society",
                image: `${process.env.REACT_APP_S3_URL}/assets/img/logo.jpg`,
                order_id: res.id,
                handler: function (response) {
                    const textForStorage = response.razorpay_payment_id
                    localStorage.setItem('my-key', textForStorage);
                    const textFromStorage = localStorage.getItem('my-key');

                    if (textFromStorage) {
                        // sendDonationICS(data, res.donationId, final_amt);
                        navigate(`/thank-you/${res.donationId}/donation`, { replace: true });

                    }
                },
                prefill: {
                    name: res.name ? res.name : "",
                    email: res.email ? res.email : "",
                    contact: res.phone ? res.phone : "",
                },
                notes: {
                    type: "nityasevadonation",
                },
                theme: {
                    color: "#FFD370",
                },
                modal: {
                    ondismiss: async function () {
                        const cancelDonation = await ApiService.cancelDonation(res.id);
                    },
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();

            paymentObject.on("payment.failed", function (response) {
                // console.log(response)
                navigate(`/payment-failed?id=${res.donationId}&&type=donation`, {
                    replace: true,
                });
            });
            setLoading(false);
            // recaptchaRef.current.reset();
        } catch (err) {
            setLoading(false);
            console.log(err);
            notification("error", "Unable to make donation, try again later!");
        }
    };

    const handleDonate = async (data) => {
        setLoading(true);
        //  const ress = await ApiService.getIcsData(data.phone)
        // // setICSdatawl(ress.data);
        // // console.log(ress.data[0].address.line1);
        // if (data.pan) {
        //   let check_len = ress.data
        //     .filter((dat) => dat.name === data.name)
        //     .some((item) => item.pan?.trim() === data.pan.toUpperCase());

        //   if (check_len) {
        //    // console.log("match");
        //   } else {
        //     notification('warning', "Make sure your PAN Number is correct !");
        //   }


        //   //console.log(check_len);
        // }

        madeDonation(data);

    };



    const resendOtp = () => {
        setOtpExp(expiry);
        handleSendOtp(getValues("phone"));
        setShowResendLink(false);
    };

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const handleDonationTypechange = (e) => {
        const indx = e.nativeEvent.target.selectedIndex;
        setIcsDontype(e.nativeEvent.target[indx].text);
        let _donationType = popularDonation.find(
            (type) => type._id === e.target.value
        );
        setDonationImg(_donationType.image);
        setVideoUrl(_donationType.videoUrl);
        setIsGoseva(_donationType.goseva);
        setIsSadhuBhojanSeva(_donationType.sadhuBhojanSeva);
        setIsBhagvadGita(_donationType.bhagvadGita);
        if (!_donationType.bhagvadGita) {
            // setValue("bhagavadGita", "");
            setBhagavadGitaPrice(0)
        }
        if (!_donationType.sadhuBhojanSeva) {
            setValue("sadhuBhojanSeva", "");
        }
        if (!_donationType.goseva) {
            setGoseva(0);
        }

    };

    const [meta, setMeta] = useState(null);
    const fetchMeta = async (pagename) => {
        try {
            let res = await ApiService.fetchMetaData(pagename);
            setMeta(res);
        } catch (error) {
            console.log(error);
        }
    };

    const retryDonation = async () => {
        try {
            const res = await ApiService.retryDonation(donationId);
            console.log(res, 'retry')

            setIsRetry(true);
            setRetryDonationDetails(res);
            setIsSadhuBhojanSeva(false);
            setIsBhagvadGita(false);
            setIsGoseva(false);
            setGoseva(0);
            setBhagavadGitaPrice(0)
            let addOns = {};
            for (let i = 0; i < res.donationAddOns.length; i++) {
                switch (res.donationAddOns[i].name) {
                    case "sadhuBhojanSeva":
                        addOns.sadhuBhojanSeva = res.donationAddOns[i].amount;
                        setIsSadhuBhojanSeva(true);
                        break;
                    case "bhagavadGita":
                        addOns.bhagavadGita = Number(res.donationAddOns[i].amount) / 200;
                        setBhagavadGitaPrice(Number(res.donationAddOns[i].amount) / 200);
                        setIsBhagvadGita(true);
                        break;
                    case "goseva":
                        addOns.goseva = Number(res.donationAddOns[i].amount) / 120;
                        setGoseva(Number(res.donationAddOns[i].amount) / 120);
                        setIsGoseva(true);
                        break;
                }
            }

            reset({
                ...res,
                ...addOns,
                donationType: res.type,
            });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchMeta("donation-form");
        if (donationId) {
            retryDonation();
        }
    }, [isRetry, donationId]);

    useEffect(() => {
        if (!user) {
            if (otpExp === 0) {
                setShowTimer(false);
                setShowResendLink(true);
                clearInterval(timer);
                setOtpExp(expiry);
            }
        }
    }, [otpExp, expiry, user]);


    //GET ALL ICS PROFILE  FROM ICS THROUGH PHONE NUMBER

    const handleGetIcsData = async (aa) => {


        const res = await ApiService.getIcsData(aa)

        setICSdata(res.data);
        if (res.data[0].id > 0) {

            if (!token) {
                setLoginPopup(true);
            }

        }
    }

    // console.log(item_data);

    //GET ICS PROFILE THROUGH PARTICULAR PHONE NUMBER

    // const getIcsData = async (e) => {
    //   const val = e.target.value.length;
    //   console.log(e.target.value)
    //   try {
    //     if (val === 10) {
    //       const res = await ApiService.getIcsData(e.target.value)
    //       //console.log(res)
    //       setICSdatawl(res.data);

    //     } else {
    //       setICSdatawl('');
    //       setItemData('')
    //       setICSdata('');
    //       localStorage.setItem('ics_id', '')
    //       setIcsAddress('')
    //       setIcsPincode('')
    //     }

    //   } catch (error) {
    //     console.log(error)
    //   }

    // }



    //GET ICS PROFILE THROUGH FETCH BY NAME BY USING FILTER

    // const fetchNameFromIcs = async (e) => {

    //   const res = await ApiService.getIcsData(user.phone)

    //   let check_len = res.data.filter((dat) => (dat.name).toUpperCase() === (e.target.value).toUpperCase())
    //   .map((item) => item.pan );

    //   console.log(check_len);

    // };



    //MATCHING PAN NUMBER WITH ICS

    // const fetchPanFromIcs = (e) => {
    //   const val = e.target.value.length;

    //   let pan_A = e.target.value.toUpperCase();
    //   let pan_B = e.pan.toUpperCase();

    //   if (val === 10) {

    //     if (pan_A === pan_B) {
    //       console.log("match")
    //     }
    //     else {
    //       notification('warning', "Make sure your PAN Number is correct !");
    //     }

    //   }

    // };


    const { loginPopup, setLoginPopup } = useContext(AppContext);

    //console.log(user.number)

    const campaignRef = useCallback((node) => {
        if (node) {
            let headerHeight;
            if (window.innerWidth > 991) {
                headerHeight = 121;
            } else if (window.innerWidth > 576) {
                headerHeight = 80;
            } else {
                headerHeight = 73;
            }
            setTop(node?.offsetTop - headerHeight);
        }
    });






    const [page, setPage] = useState(1);

    //const icss_id = localStorage.getItem('ics_id');

    const fetchFromIcsScheme = async () => {

        try {
            const res = await ApiService.fetchUserDonation(page);
            console.log(res.donations);
            setICSscheme(res.donations.filter(dat => dat.orderId === id));
        } catch (err) {
            console.log(err);
            notification("error", err.response.data.message);
        }

    }

    console.log(scheme_list)


    useEffect(() => {
        fetchFromIcsScheme();


        reset({
            name: scheme_list[0]?.name,
            phone: scheme_list[0]?.phone,
            email: scheme_list[0]?.email,
            pan: scheme_list[0]?.pan,
            address: (scheme_list[0]?.streetName && scheme_list[0]?.areaName) ? `${scheme_list[0]?.streetName}, ${scheme_list[0]?.areaName}` : scheme_list[0]?.address,
            pin: scheme_list[0]?.pin,
            state: scheme_list[0]?.state,
            city: scheme_list[0]?.city,
            amount: scheme_list[0]?.amount,
            donationType: scheme_list[0]?.schemaname,

        });
    }, [user]);


    useEffect(() => {
        campaignRef();
        if (token) {
            handleGetIcsData(localStorage.getItem('ics_number'));
        }
    }, [token])

    return (
        <>
            <MetaData title="Donation Form - ISKCON of Attapur" />
            <PageHeader title="Donation form" imgSrc="/images/page-header/17.webp" />
            <section className="donation-form-sec" ref={formRef} >
                <div className="container"  >
                    <div className="row d-flex justify-content-center mb-5" id="scrollToDonateFrom" >
                        <div className="col-md-10">
                            <div id="scrollToDonationForm" className="heading">
                                <h2 className="head-1">QUICK DONATE</h2>
                                <p className="b-line">
                                    If you would like to make a donation towards a particular area
                                    of activity, please select an option from below. ISKCON relies
                                    entirely on voluntary donations and so every donation counts.
                                    Please note that donation is processed on a secure site.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="form-wrap my-5"
                        style={{ scrollMarginBlockStart: "150px" }}
                    >
                        <form onSubmit={handleSubmit(handleDonate)}>
                            <div className="row align-items-start">
                                <div className="col-lg-4 pe-xl-4">
                                    <div className="donate-img">
                                        {donationType ? (
                                            <>
                                                {donationType?.videoUrl ? (
                                                    <Fancybox>
                                                        {donationType?.type === "donationType" ? (
                                                            <a
                                                                data-fancybox="video"
                                                                href={donationType?.videoUrl}
                                                                data-thumb={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                                                            >
                                                                <figure className="up-right hasVid">
                                                                    {donationImg ? (
                                                                        <>
                                                                            <img
                                                                                src={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                                                                                alt=""
                                                                            />
                                                                            <span className="donate-play">
                                                                                <span className="wave">
                                                                                    <BsFillPlayCircleFill />
                                                                                </span>
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <Skeleton height={320} />
                                                                    )}
                                                                </figure>
                                                            </a>
                                                        ) : (
                                                            <a
                                                                data-fancybox="video"
                                                                href={donationType?.videoUrl}
                                                                data-thumb={`${process.env.REACT_APP_S3_URL}/uploads/festival/${donationImg}`}
                                                            >
                                                                <figure className="up-right hasVid">
                                                                    {donationImg ? (
                                                                        <>
                                                                            <img
                                                                                src={`${process.env.REACT_APP_S3_URL}/uploads/festival/${donationImg}`}
                                                                                alt=""
                                                                            />
                                                                            <span className="donate-play">
                                                                                <span className="wave">
                                                                                    <BsFillPlayCircleFill />
                                                                                </span>
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <Skeleton height={320} />
                                                                    )}
                                                                </figure>
                                                            </a>
                                                        )}
                                                    </Fancybox>
                                                ) : (
                                                    <>
                                                        {donationType?.type === "donationType" ? (
                                                            <figure className="up-right">
                                                                {donationImg ? (
                                                                    <img
                                                                        src={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                                                                        alt=""
                                                                    />
                                                                ) : (
                                                                    <Skeleton height={320} />
                                                                )}
                                                            </figure>
                                                        ) : (
                                                            <figure className="up-right">
                                                                {donationImg ? (
                                                                    <img
                                                                        src={`${process.env.REACT_APP_S3_URL}/uploads/festival/${donationImg}`}
                                                                        alt=""
                                                                    />
                                                                ) : (
                                                                    <Skeleton height={320} />
                                                                )}
                                                            </figure>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {videoUrl ? (
                                                    <>
                                                        <Fancybox>
                                                            <a
                                                                data-fancybox="video"
                                                                href={donationType?.videoUrl}
                                                                data-thumb={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                                                            >
                                                                <figure className="up-right hasVid">
                                                                    {donationImg ? (
                                                                        <>
                                                                            <img
                                                                                src={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                                                                                alt=""
                                                                            />
                                                                            <span className="donate-play">
                                                                                <span className="wave">
                                                                                    <BsFillPlayCircleFill />
                                                                                </span>
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <Skeleton height={320} />
                                                                    )}
                                                                </figure>
                                                            </a>
                                                        </Fancybox>
                                                    </>
                                                ) : (
                                                    <>
                                                        <figure className="up-right">
                                                            {donationImg ? (
                                                                <img
                                                                    src={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <Skeleton height={320} />
                                                            )}
                                                        </figure>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div ref={campaignRef} className="form-part mt-4 me-lg-2">
                                        <div className="notes-wrap mt-0">
                                            <p className="text-center">
                                                <span> Please Note:</span> Complete Address with
                                                PIN-Code and PAN is mandatory for an 80G Receipt.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 mt-lg-0 mt-4 ">
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="form-part">
                                                <label htmlFor="">Donation For - Nitya Seva</label>

                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    {...register("donationType", {})}
                                                    readOnly
                                                />

                                                {errors.donationType?.type === "required" && (
                                                    <small className="text-danger error">
                                                        This is required
                                                    </small>
                                                )}
                                                {/* <Link
                                  className="donation-link"
                                  to="/donate#all-donation"
                                >
                                  view more donation options
                                </Link> */}
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="form-part">
                                                <label htmlFor="">Amount</label>

                                                <input
                                                    type="text"
                                                    placeholder="Enter Amountt"
                                                    {...register("amount", { required: true })}
                                                    readOnly
                                                />

                                                {errors.amount?.type === "required" && (
                                                    <small className="text-danger error">
                                                        This is required
                                                    </small>
                                                )}
                                                {errors.amount && (
                                                    <small className="text-danger error">
                                                        {errors.amount.message}
                                                    </small>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-part">
                                                <label htmlFor="">Mobile Number</label>
                                                <input
                                                    type="tel"
                                                    placeholder="Phone Number"
                                                    // onKeyUp={getIcsData}
                                                    maxLength={10}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    readOnly
                                                    // disabled=
                                                    {...register("phone", {
                                                        required: true,
                                                        pattern: {
                                                            value: /^[6-9]\d*$/,
                                                            message: "Please provide valid phone number",
                                                        },
                                                        minLength: {
                                                            value: 10,
                                                            message: "Phone number must be minimum 10 digit",
                                                        },
                                                    })}
                                                />
                                                {errors.phone?.type === "required" && (
                                                    <small className="text-danger error">
                                                        This is required
                                                    </small>
                                                )}
                                                {errors.phone && (
                                                    <small className="text-danger error">
                                                        {errors.phone.message}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-part">
                                                <label htmlFor="">Email</label>
                                                <input
                                                    type="text"
                                                    readOnly
                                                    {...register("email", {
                                                        required: true,
                                                        pattern: {
                                                            value:
                                                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message: "Please enter a valid email",
                                                        },
                                                    })}
                                                    placeholder="Email"
                                                />
                                                {errors.email?.type === "required" && (
                                                    <small className="text-danger error">
                                                        This is required
                                                    </small>
                                                )}
                                                {errors.email && (
                                                    <small className="text-danger error">
                                                        {errors.email.message}
                                                    </small>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-part">
                                                <label htmlFor="">Name</label>
                                                <input
                                                    maxLength={50}
                                                    type="text"
                                                    readOnly
                                                    // onKeyUp={fetchNameFromIcs}
                                                    {...register("name", {
                                                        required: true,
                                                        maxLength: {
                                                            value: 50,
                                                            message:
                                                                "Name can't exceeds more than 50 characters",
                                                        },
                                                        pattern: {
                                                            value: /^[A-Za-z ]*$/,
                                                            message: "Special Characters not allowed !",
                                                        },
                                                    })}
                                                    onKeyPress={(event) => {
                                                        if (/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    placeholder="Name"
                                                />
                                                {errors.name?.type === "required" && (
                                                    <small className="text-danger error">
                                                        This is required
                                                    </small>
                                                )}
                                                {errors.name?.message && (
                                                    <small className="text-danger error">
                                                        {errors.name?.message}
                                                    </small>
                                                )}
                                                {/* {token ?
                                                    <Link
                                                        className="donation-link"
                                                        onClick={handelOptions}
                                                    >
                                                        Select Options
                                                    </Link>
                                                    : ''} */}

                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-part">
                                                <label htmlFor="">PAN (Optional)</label>
                                                <Tooltip title="For 80G reciept" placement="bottom-start">
                                                    <input
                                                        type="text"
                                                        style={{ textTransform: "uppercase" }}
                                                        name=""
                                                        id=""
                                                        readOnly={scheme_list[0]?.pan ? true : false}
                                                        // onKeyUp={fetchPanFromIcs}
                                                        maxLength={10}
                                                        placeholder="PAN"
                                                        {...register("pan", {
                                                            pattern: {
                                                                value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                                                                message: "Please enter valid PAN",
                                                            },
                                                        })}
                                                    />
                                                </Tooltip>
                                                {errors.pan && (
                                                    <small className="text-danger error">
                                                        {errors.pan.message}
                                                    </small>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-part">
                                                <label htmlFor="">Address </label>
                                                <Tooltip
                                                    title="For Prasadam please provide this"
                                                    placement="bottom-start"
                                                >
                                                    <textarea
                                                        defaultValue={ics_address}
                                                        type="text"
                                                        {...register("address", {
                                                            minLength: {
                                                                value: 10,
                                                                message:
                                                                    "address must be minimum 10 characters",
                                                            },
                                                        })}
                                                        placeholder="Address"
                                                        {...register("address", {
                                                            required: true,
                                                        })}
                                                    ></textarea>
                                                </Tooltip>
                                                {errors.address?.type === "required" && (
                                                    <small className="text-danger error">
                                                        This is required
                                                    </small>
                                                )}

                                                {errors.address?.message && (
                                                    <small className="text-danger error">
                                                        {errors.address?.message}
                                                    </small>
                                                )}
                                                {/* {errors.address && (
                          <small className="error">
                            {errors.address.message}
                          </small>
                        )} */}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-part">
                                                <label htmlFor="">PIN Code</label>
                                                <input
                                                    defaultValue={ics_pincode}
                                                    type="text"
                                                    minLength={6}
                                                    maxLength={6}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    {...register("pin", {
                                                        pattern: {
                                                            value: /^\d+$/,
                                                            message: "PIN code must be number.",
                                                        },
                                                        onChange: (e) => {
                                                            setValue("state", "");
                                                            setValue("city", "");
                                                            if (e.target.value.length === 6) {
                                                                fetchStateCity(e.target.value);
                                                            }
                                                        },
                                                    })}
                                                    {...register("pin", {
                                                        required: true,
                                                    })}
                                                    placeholder="PIN Code"
                                                />

                                                {errors.pin && (
                                                    <small className="text-danger error">
                                                        {errors.pin.message}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                        {/* {isGoseva && (
                          <div className="col-xl-6">
                            <div className="donationAddOns mb-3">
                              <div className="row">
                                <div className="col-xl-5 col-sm-6">
                                  <p>
                                    Perform Goseva by feeding the cows.{" "}
                                    <span className="sub">(Optional)</span>
                                  </p>
                                </div>
                                <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                                  <div className="donationAddOns-board">
                                    <div className="amount">
                                      <img
                                        src="/images/donation/cows-min.png"
                                        alt=""
                                      />
                                      <span>₹120.00</span>
                                    </div>
                                    <div className="count-wrap">
                                      <div className="count">
                                        <button
                                          type="button"
                                          className={goseva <= 0 ? "disabled" : ""}
                                          onClick={() => {
                                            setGoseva(goseva - 1)
                                            setValue('goseva', goseva - 1)
                                          }}
                                        >
                                          <span>-</span>
                                        </button>
                                        <span className="showCount">{goseva}</span>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setGoseva(goseva + 1)
                                            setValue('goseva', goseva + 1)
                                          }}
                                        >
                                          <span>+</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="sub">
                                    Feed a Cow @ Rs.120 for a day. Select no. of
                                    Cows to feed
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {isSadhuBhojanSeva && (
                          <div className="col-xl-6">
                            <div className="donationAddOns mb-3">
                              <div className="row">
                                <div className="col-xl-5 col-sm-6">
                                  <p>
                                    Perform Sadhu Bhojan Seva.{" "}
                                    <span className="sub">(Optional)</span>
                                  </p>
                                </div>
                                <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                                  <div className="donationAddOns-board">
                                    <div className="amount">
                                      <img
                                        src="/images/donation/sadhu-min.png"
                                        alt=""
                                      />
                                      <input
                                        {...register("sadhuBhojanSeva")}
                                        type="text"
                                        placeholder="Enter Amount"
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <span className="sub">
                                    Sponsor Bhojan Seva for Brahmanas. Contribute
                                    any amount.
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                                        {/* {isBhagvadGita && (
                          <div className="col-xl-6">
                            <div className="donationAddOns mb-3">
                              <div className="row">
                                <div className="col-xl-5 col-sm-6">
                                  <p>
                                    Sponsor Printing of Bhagavad Gita for free
                                    distribution{" "}
                                    <span className="sub"> (Optional)</span>
                                  </p>
                                </div>
                                <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                                  <div className="donationAddOns-board">
                                    <div className="amount">
                                      <img
                                        src="/images/donation/bhagavad-gita-min.png"
                                        alt=""
                                      />
                                      <input
                                        {...register("bhagavadGita")}
                                        type="text"
                                        placeholder="Enter Amount"
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <span className="sub">
                                    Sponsor Printing of Bhagavad Gita for free
                                    distribution in Villages, Schools, Orphanages,
                                    Jails, etc. (₹ 160 per book)
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                                        {/* {isBhagvadGita && (
                          <div className="col-xl-6">
                            <div className="donationAddOns mb-3">
                              <div className="row">
                                <div className="col-xl-5 col-sm-6">
                                  <p>
                                    Sponsor Printing of Bhagavad Gita for free
                                    distribution{" "}
                                    <span className="sub"> (Optional)</span>
                                  </p>
                                </div>
                                <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                                  <div className="donationAddOns-board">
                                    <div className="amount">
                                      <img
                                        src="/images/donation/bhagavad-gita-min.png"
                                        alt=""
                                      />
                                      <span>₹ 200.00</span>
                                    </div>
                                    <div className="count-wrap">
                                      <div className="count">
                                        <button
                                          type="button"
                                          className={
                                            bhagavadGitaPrice <= 0 ? "disabled" : ""
                                          }
                                          onClick={() => {
                                            setBhagavadGitaPrice(bhagavadGitaPrice - 1)
                                            setValue('bhagavadGita', bhagavadGitaPrice - 1)
                                          }}
                                        >
                                          <span>-</span>
                                        </button>
                                        <span className="showCount">
                                          {bhagavadGitaPrice}
                                        </span>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setBhagavadGitaPrice(bhagavadGitaPrice + 1)
                                            setValue('bhagavadGita', bhagavadGitaPrice + 1)
                                          }}
                                        >
                                          <span>+</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="sub">
                                    Sponsor Printing of Bhagavad Gita for free
                                    distribution in Villages, Schools, Orphanages,
                                    Jails, etc. (₹ 200 per book)
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                                        <div className="col-12">
                                            <div className="type_checkbox mt-3">
                                                <input
                                                    type="checkbox"
                                                    id="memoryOfSomeone"
                                                    {...register("memoryOfSomeone")}
                                                />
                                                <label htmlFor="memoryOfSomeone">
                                                    This Donation in the memory/honor of someone or performed on
                                                    a specific occasion
                                                </label>
                                            </div>
                                            {showMemoryBox && (
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-part">
                                                            <label htmlFor="inNameOf">Name </label>
                                                            <input
                                                                type="text"
                                                                placeholder="In Name Of"
                                                                {...register("inNameOf")}
                                                            />

                                                            {errors.inNameOf?.message && (
                                                                <small className="text-danger error">
                                                                    {errors.inNameOf?.message}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6">
                                                        <div className="form-part">
                                                            <label htmlFor="relationwithDonor">
                                                                Relation
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Relation"
                                                                {...register("relationwithDonor")}
                                                            />

                                                            {errors.relationwithDonor?.message && (
                                                                <small className="text-danger error">
                                                                    {errors.relationwithDonor?.message}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6">
                                                        <div className="form-part">
                                                            <label htmlFor="occasion">Occasion</label>
                                                            <select {...register("occasion")}>
                                                                <option value="birthday">
                                                                    Select Occasion
                                                                </option>
                                                                <option value="Birthday">Birthday</option>
                                                                <option value="MarriageAnniversary">
                                                                    Marriage Anniversary
                                                                </option>
                                                                <option value="DeathAnniversary">
                                                                    Death Anniversary
                                                                </option>
                                                                <option value="OtherOccasion">
                                                                    Any Other Occasion
                                                                </option>
                                                            </select>

                                                            {errors.occasion?.message && (
                                                                <small className="text-danger error">
                                                                    {errors.occasion?.message}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6">
                                                        <div className="form-part">
                                                            <label htmlFor="inDate">Occasion Date</label>
                                                            <div className="input-date">
                                                                <input
                                                                    type="date"

                                                                    placeholder="Date"
                                                                    {...register("inDate", { required: true })}
                                                                    min={getCurrentDate()}
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="form-part mt-2 memory_field">
                                <textarea
                                  type="text"
                                  {...register("memoryText")}
                                ></textarea>
                              </div> */}
                                                    <label htmlFor="">Message</label>
                                                    <textarea
                                                        type="text"
                                                        maxLength={250}
                                                        {...register("memoryText")}
                                                    ></textarea>
                                                    <small>* Minimum 250 characters allowed</small>
                                                </div>
                                            )}
                                        </div>
                                        {/* <div className="col-12">
                          <div className="type_checkbox mt-3">
                            <input
                              type="checkbox"
                              id="memoryOfSomeone"
                              {...register("memoryOfSomeone")}
                            />
                            <label htmlFor="memoryOfSomeone">
                              Is this donation in memory of someone or performed on
                              a specific day?
                            </label>
                          </div>
                          {showMemoryBox && (
                            <div className="form-part mt-2 memory_field">
                              <textarea
                                type="text"
                                maxLength={250}
                                {...register("memoryText")}
                              ></textarea>
                               <small>* Minimum 250 characters allowed</small>
                            </div>
                          )}
                        </div> */}
                                        <div className="col-12">
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                                ref={recaptchaRef}
                                                size="invisible"
                                            />
                                        </div>
                                        <div className="col-12 mt-4">
                                            <button
                                                type="submit"
                                                className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form"
                                                disabled={loading ? true : false}
                                            >
                                                {loading ? (
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className="spinner-border text-light"
                                                            role="status"
                                                        ></div>
                                                        <div className="ms-2">DONATING...</div>
                                                    </div>
                                                ) : (

                                                    <>
                                                        {Soa ?
                                                            <>
                                                                DONATE NOW ₹{Soa > 0 && `${Soa.toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' })}`}/-
                                                            </>


                                                            :
                                                            <>
                                                                DONATE NOW {totalAmount > 0 && `${totalAmount.toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' })}/-`}

                                                            </>

                                                        }


                                                    </>
                                                )}
                                            </button>
                                            {showOtp && (
                                                <>
                                                    <div className="form-part d-inline-block my-2">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter OTP"
                                                            maxLength={6}
                                                            minLength={6}
                                                            value={otp}
                                                            onChange={(e) => setOtp(e.target.value)}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                        {!showresendlink && showTimer && (
                                                            <span
                                                                style={{
                                                                    color: "#9F1D21",
                                                                    display: "inline-block",
                                                                    marginLeft: "10px",
                                                                }}
                                                            >
                                                                {getTime(otpExp)}s
                                                            </span>
                                                        )}
                                                    </div>
                                                    <>
                                                        {showresendlink && (
                                                            <button
                                                                className="custom-btn-cls box-hover"
                                                                onClick={resendOtp}
                                                            >
                                                                RESEND
                                                            </button>
                                                        )}
                                                    </>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="donate-note my-5">
                        <p>
                            Note:  AVAIL 80G BENEFITS ON ALL THE DONATIONS MADE TO ISKCON ATTAPUR (Preaching center- ISKCON HYDERABAD),
                        </p>
                        <p>
                            "Exemption order ref no. AAATI0017PF2021901 dated 24/09/2021 valid upto 31/03/2026"
                        </p>
                    </div>
                </div>
            </section>
            <GoToTop top={top} />
        </>
    );
};

export default Repayment;
