import PageHeader from "../components/common/PageHeader";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import { useForm } from "react-hook-form";
import IskconStoryVideo from "../components/about/IskconStoryVideo";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";

const Volunteer = () => {

  const scrollToElement = () => {
    const element = document.getElementById('scrollToAllPages');
    // console.log("elements",element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  };

  const { user } = useContext(AppContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const volunteerSliderRef = useRef();
  const recaptchaRef = useRef();

  const onSubmitVolunteer = async (data) => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      // console.log(token)
      // console.log(data)
      data.token = token;

      const res = await ApiService.newVolunteer(data);
      notification("success", "Hare Krishna! Your form has been accepted.");
      reset();
      recaptchaRef.current.reset();
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }
  };

  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMeta("volunteer");
  }, []);

  useEffect(() => {

    scrollToElement();
  }, [])

  useEffect(() => {
    if (user) {
      reset({
        firstname: user.name.split(" ")[0],
        lastname: user.name.split(" ")[1],
        phone: user.phone,
        email: user.email,
        address: user.address,
      });
    }
  }, [user]);

  return (
    <>
      <MetaData title="Volunteer - ISKCON of Attapur" />
      <PageHeader title="VOLUNTEER" imgSrc="/images/page-header/02.webp" />
      <section className="volunteer">
        <div className="container">
          <div className="row" id="scrollToAllPages">
            <div className="col-md-10 mx-auto">
              <div className="heading">
                <h2 className="head-1">OFFER YOUR TIME AND TALENT</h2>
                <div className="b-line">
                  <p className="mb-1">
                    We welcome devotees to partake in seva by offering their
                    time and talent to Krishna and working alongside our small
                    community of devotees at the Sri Sri Radha Madhava Temple,
                    Attapur.
                  </p>
                  <p className="my-1">
                    No task is small and any service offered to the Lord is
                    received by Him.
                  </p>
                  <p className="my-1">
                    You can choose from one of the volunteer opportunities
                    mentioned below or if you have a special talent and would
                    like to offer it to the Lord; we welcome you with open arms.
                  </p>
                  <p className="my-1">Hare Krishna!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="volunteer-info-sec">
        <div className="container">
          <div className="row ">
            <div className="col-lg-7 ">
              <div className="heading">
                <h2 className="head-1">OPPORTUNITIES</h2>
              </div>
              <ul>
                <li className="bullet">Volunteer in the community kitchen</li>
                <li className="bullet">Volunteer for book distribution</li>
                <li className="bullet">
                  Volunteer for food distribution services{" "}
                </li>
                <li className="bullet">Volunteer for festival services </li>
                <li className="bullet">
                  Volunteer to partake in street Harinam{" "}
                </li>
                <li className="bullet">
                  Volunteer your professional expertise in any of the
                  below-mentioned fields:
                  <ul className="my-2">
                    <li className="bullet">Medical Services</li>
                    <li className="bullet"></li>
                    <li className="bullet">Architect, Interiors and allied </li>
                    <li className="bullet">IT and Computer Management </li>
                    <li className="bullet">Editorial Services, Publishing</li>
                    <li className="bullet">Graphic Design and fine arts</li>
                    <li className="bullet">Social Media </li>
                    <li className="bullet">
                      Photography, Filmmaking and allied{" "}
                    </li>
                    <li className="bullet">Drama and Performing Arts </li>
                  </ul>
                </li>
                <li className="bullet">
                  For all other Volunteer Opportunities and to have any specific
                  questions answered, please contact:
                  <p className="mb-0">
                    <b>Phone: </b> +91-9182744307
                  </p>
                  <p className="mb-0">
                    <b>What's app: </b> +91-9182744307
                  </p>
                  <p className="mb-0">
                    <b>Email: </b>  <a href="mailto:support@iskconattapur.com">
                      support@iskconattapur.com
                    </a>{" "}
                  </p>

                </li>
                {/* <li className="bullet">
                    For more information please contact:{" "}
                    <a href="tel:+91 9930398330">+91 9930398330</a>{" "}
                  </li> */}
              </ul>
            </div>
            <div className="col-lg-5 mt-5 mt-lg-0 ">
              <div className="volunteer-form">
                <div className="heading">
                  <h2 className="head-1">VOLUNTEER FORM</h2>
                </div>
                <form
                  className="order-0 order-lg-1"
                  onSubmit={handleSubmit(onSubmitVolunteer)}
                >
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <div className="input-wrap">
                        <label htmlFor="firstname" className="imp">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstname"
                          {...register("firstname", { required: true })}
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.firstname?.type === "required" && (
                          <span> First name is required </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-wrap">
                        <label htmlFor="lastname" className="imp">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="lastname"
                          {...register("lastname", { required: true })}
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.lastname?.type === "required" && (
                          <span> Last name is required </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-wrap">
                        <label htmlFor="email" className="imp">
                          Email{" "}
                        </label>
                        <input
                          type="email"
                          id="email"
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email",
                            },
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <span> Email address is required </span>
                        )}
                        {errors.email && (
                          <small className="text-danger error">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-wrap">
                        <label htmlFor="phone" className="imp">
                          Phone
                        </label>
                        <input
                          type="tel"
                          id="phone"
                          minLength={10}
                          maxLength={13}
                          {...register("phone", {
                            required: true,
                            minLength: 10,
                            maxLength: 13,
                          })}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-wrap">
                        <label htmlFor="message" className="imp">
                          Your Message
                        </label>
                        <textarea
                          id="message"
                          {...register("message", { required: true })}
                        ></textarea>
                        {errors.message?.type === "required" && (
                          <span> Message is required </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                        size="invisible"
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <button className="box-hover custom-btn" type="submit">
                        submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <IskconStoryVideo
        imgSrc="/images/volunteer/volunteer-video.webp"
        videoLink="https://www.youtube.com/watch?v=IgOTbggOjsw"
        smallText="How can I follow Krishna"
        bigText="without becoming religious?"
      /> */}
      <section className="volunteer-slider-sec">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-10 mx-auto">
              <div className="heading text-uppercase text-center">
                <h2 className="head-1">VOLUNTEER</h2>
                <h2 className="head-2">CONTRIBUTE YOUR TIME AND TALENT </h2>
              </div>
            </div>
          </div>
          <Splide
            ref={volunteerSliderRef}
            className="volunteer-slider"
            options={{
              type: "loop",
              perPage: 3,
              perMove: 1,
              gap: 20,
              arrows: false,
              pagination: false,
              autoplay: true,
              speed: 1000,
              interval: 8000,
              breakpoints: {
                576: {
                  perPage: 1,
                  gap: 20,
                },
                768: {
                  perPage: 2,
                  trimSpace: true,
                },
                992: {
                  trimSpace: true,
                  perPage: 2,
                  gap: 20,
                },
                1024: {
                  trimSpace: true,
                  perPage: 3,
                  gap: 20,
                },
                1200: {
                  trimSpace: false,
                  perPage: 3,
                },
              },
            }}
          >
            <SplideSlide>
              <figure className="mb-0">
                <img src="/images/volunteer/volunteer-1.webp" alt="" />
              </figure>
            </SplideSlide>
            <SplideSlide>
              <figure className="mb-0">
                <img src="/images/volunteer/volunteer-2.webp" alt="" />
              </figure>
            </SplideSlide>
            <SplideSlide>
              <figure className="mb-0">
                <img src="/images/volunteer/volunteer-3.webp" alt="" />
              </figure>
            </SplideSlide>
            <SplideSlide>
              <figure className="mb-0">
                <img src="/images/volunteer/volunteer-4.webp" alt="" />
              </figure>
            </SplideSlide>
            <SplideSlide>
              <figure className="mb-0">
                <img src="/images/volunteer/volunteer-5.webp" alt="" />
              </figure>
            </SplideSlide>
            <SplideSlide>
              <figure className="mb-0">
                <img src="/images/volunteer/volunteer-6.webp" alt="" />
              </figure>
            </SplideSlide>
            <SplideSlide>
              <figure className="mb-0">
                <img src="/images/volunteer/volunteer-7.webp" alt="" />
              </figure>
            </SplideSlide>
            <SplideSlide>
              <figure className="mb-0">
                <img src="/images/volunteer/volunteer-8.webp" alt="" />
              </figure>
            </SplideSlide>
            <SplideSlide>
              <figure className="mb-0">
                <img src="/images/volunteer/volunteer-9.webp" alt="" />
              </figure>
            </SplideSlide>
          </Splide>
          {/* <div className="row mt-5">
                        <div className="col-lg-6 mx-auto text-center">
                        <Link className="custom-btn-cls box-hover" to="/donation-form">Donate now</Link>

                        </div>
                    </div> */}
        </div>
        <div className="arrow-wrap">
          <button
            className="slider-btn prev box-hover"
            onClick={() => volunteerSliderRef.current.splide.go("-1")}
          >
            <FiChevronLeft />
          </button>
          <button
            className="slider-btn next box-hover"
            onClick={() => volunteerSliderRef.current.splide.go("+1")}
          >
            <FiChevronRight />
          </button>
        </div>
      </section>
      <GoToTop />
    </>
  );
};

export default Volunteer;
