import { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { VscThreeBars } from "react-icons/vsc";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { FiExternalLink } from "react-icons/fi";
import { FaFacebookF, FaYoutube, FaInstagram } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { HiUser } from "react-icons/hi";
import { Modal } from "react-bootstrap";
import AppContext from "../context/AppContext";
import ApiService from "../api/ApiService";
import notification from "../helpers/notification";
import { RiLiveFill } from "react-icons/ri";
import axios from "axios";
import PageHeader from "../components/common/PageHeader";
import MetaData from "../helpers/MetaData";

const expiry = process.env.REACT_APP_OTPEXPIRY;
let timer = null;

const Header = ({ setOpened }) => {
  const { loginPopup, setLoginPopup, token, setToken, user } =
    useContext(AppContext);

  const [isScroll, setIsScroll] = useState(false);
  const navigate = useNavigate();
  const updateOnScroll = () =>
    window.addEventListener("scroll", () =>
      window.scrollY > 20 ? setIsScroll(true) : setIsScroll(false)
    );

  useEffect(() => {
    updateOnScroll();
  }, []);

  const search = useLocation().search;
  const link_id = new URLSearchParams(search).get("link");
  const sub = new URLSearchParams(search).get("sub");
  const buttonRef = useRef(null);

  const [otpSent, setOtpSent] = useState(false);
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpExp, setOtpExp] = useState(expiry);
  const [resendtimes, setResendTimes] = useState(0);
  const [showresendlink, setShowResendLink] = useState(false);
  const [isWatchLive, setIsWatchLive] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [seticsmob, SetIcsmob] = useState([]);



  const hasWatchLive = async () => {
    const { liveVideos } = await ApiService.hasWatchLive();
    setIsWatchLive(liveVideos);
  };

  if (resendtimes == 2) {
    navigate("/donation-form")
    setResendTimes(0)
    setLoginPopup(false)
    setShowResendLink(false)
    setOtpSent(false)
  }
  // if(token){
  //   if(link_id){
  //   navigate('/dashboard?link='+link_id)
  //   }else{
  //   navigate('/dashboard')
  //   }
  // }

  if (token) {
    if (link_id) {

      navigate('/dashboard?link=' + link_id)


    } else if (sub) {

      navigate('/nityaseva-form')

    } else {
      navigate('/dashboard')
    }
  }

  function getTime(time) {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }

  if (link_id) {
    localStorage.setItem("icsmob", link_id)
  }

  const handleSendOtp = async () => {
    const getmob = localStorage.getItem("icsmob");
    if (getmob) {
      setNumber(localStorage.getItem("icsmob"));
    }

    if (timer) {
      clearInterval(timer);
    }
    if (number.length >= 10) {
      try {
        setBtnDisabled(true);
        const res = await ApiService.sendOtp({ number });
        notification("success", res.message);
        setBtnDisabled(false);
        timer = setInterval(() => {
          setOtpExp((prev) => prev - 1);
        }, 1000);

        setOtpSent(true);
      } catch (err) {
        console.log(err);
        if (err.response.status === 400) {
          notification("warning", err.response.data.message);
        } else {
          if (err.response.data.message == 'No donation found for the given mobile number!') {
            navigate("/donation-form")
            setLoginPopup(false);
            notification('error', err.response.data.message)
          } else {
            notification("error", err.response.data.message);
          }
        }
        setBtnDisabled(false);
      }
    } else {
      notification("warning", "Please Enter Valid Number");
      setBtnDisabled(false);
    }
  };

  const handleLogin = async () => {
    if (otp.length >= 6) {
      try {
        let data = {
          number,
          otp,
        };

        ///ICS START///
        const response = await ApiService.getIcsData(number);
        // const url = process.env.REACT_APP_ICS_URL
        // // const url = 'http://localhost:8080/ics/api/actionHandler'
        // const response = await axios.get(url,{
        //     params:{
        //         cn:'donation',
        //         an:'getICSids', //or getDonations
        //         phone: number,
        //         mode: 'DETAILED'    //DETAILED or DETAILED_WITH_DONATIONS or blank
        //     },
        //     withCredentials: true,
        //     auth: {
        //    username: process.env.REACT_APP_ICS_USERNAME,
        //    password: process.env.REACT_APP_ICS_PASSWORD
        // }
        // });

        console.log(response.data[0]);
        localStorage.setItem("ics_id", response.data[0].id);
        localStorage.setItem("ics_name", response.data[0].name);
        localStorage.setItem("ics_number", response.data[0].number);

        ///ICS  END///

        const res = await ApiService.loginUser(data);
        // console.log(res)
        localStorage.setItem(`token`, res.token);
        setToken(res.token);
        setLoginPopup(false);
        notification("success", res.name + " " + res.message);
        setOtpSent(false);

        setOtp("");
      } catch (err) {
        // console.log(err);
        notification("error", err.response.data.message);
      }
    } else {
      notification("warning", "Please Enter Valid OTP");
    }
  };

  function setCookie(name, value) {
    let expires = "";
    let date = new Date();
    date.setTime(date.getTime() + 1 * 60 * 1000);
    expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const resendOtp = () => {
    setShowResendLink(false);

    if (getCookie("limitReached2")) {
      notification("warning", "Resend OTP limit has been reached!");
      setResendTimes(0);
      setShowTimer(false);
    } else {
      if (resendtimes < 2) {
        setOtpExp(expiry);
        handleSendOtp();
        setResendTimes((prev) => prev + 1);
      } else {
        setCookie("limitReached2", true);
        notification("warning", "Resend OTP limit has been reached!");
        setShowTimer(false);
      }
    }
  };

  const handleNavigate = () => {
    navigate("/donate");
    // window.location.reload();
  };

  const connectAchor = () => {
    setTimeout(() => {
      console.log("Document Element:", document.documentElement);
      const scrollToDonateFromRef = document.getElementById(
        "scrollToConnectPage"
      );

      if (scrollToDonateFromRef) {
        const offset = -600;
        const targetScrollTop = scrollToDonateFromRef.offsetTop - offset;

        const container = document.documentElement;

        container.scrollTop = targetScrollTop;
      } else {
        console.error("Element scrollToDonateFromRef not found!");
      }
    }, 1000);
  };

  const handleHideModal = () => {
    setLoginPopup(false);
  };

  useEffect(() => {
    hasWatchLive();
    setNumber(localStorage.getItem("icsmob"));
  }, []);

  useEffect(() => {
    if (otpExp === 0) {
      setShowResendLink(true);
      clearInterval(timer);
      setOtpExp(expiry);
    }
  }, [otpExp, expiry]);

  const quickDonateBtn = () => {
    localStorage.removeItem("SpecialOccassionAmount");
    localStorage.removeItem("responseData");
    navigate("/donation-form");
    setTimeout(() => {
      console.log("Document Element:", document.documentElement);
      const scrollToDonateFromRef =
        document.getElementById("scrollToDonateFrom");
      console.log("scrollToDonateFromRef", scrollToDonateFromRef);

      if (scrollToDonateFromRef) {
        const offset = -600;
        const targetScrollTop = scrollToDonateFromRef.offsetTop - offset;

        const container = document.documentElement;

        container.scrollTop = targetScrollTop;
      } else {
        console.error("Element scrollToDonateFromRef not found!");
      }
    }, 1000);

    localStorage.removeItem("multiplesevatype");

    localStorage.removeItem("multiplesevaPrice");

    localStorage.removeItem("multiplesevaName");
  };

  // console.log()

  // Call autoClickButton after component mounts (you can call it wherever appropriate)
  useEffect(() => {
    let intervalId;

    if (link_id & !user) {
      intervalId = setInterval(() => {
        buttonRef.current.click();
        clearInterval(intervalId); // Clear the interval after the first click
      }, 100);
    }

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [link_id]);
  return (
    <>
      <MetaData title="Login - ISKCON of Attapur" />

      <section className="connect-heading-sec">
        <div className="container">
          <div className="auth-modal">
            {/* <button className="btn close-svg" onClick={handleHideModal}>
            <MdOutlineClose />
          </button> */}
            <div className="row align-items-center" style={{ marginTop: '69px' }}>
              <div className="col-md-6 d-sm-block d-none pe-md-0">
                <div className="popup-left-side">
                  <figure className="bg_img">
                    <img src="/images/common/bg-line.jpg" alt="" />
                  </figure>
                  <div className="left-content">
                    <h4>Login</h4>
                    <div className="main-content">
                      <h3>
                        Log-in to save your details, view donation history and{" "}
                        <span> download 80G certificate </span> conveniently.
                      </h3>
                    </div>
                  </div>
                  <figure className="popup-img">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-md-6 ps-md-0">
                {otpSent ? (
                  <div className="pop-up-form">
                    <div className="pop-up-top">
                      <div className="heading">
                        <h2 className="head-2">OTP Verification</h2>
                      </div>
                    </div>
                    <p>Enter the OTP sent to +91 {number} and on your registered mail id.</p>
                    <form action="" className="mt-3 mt-sm-0">
                      <div className="row align-items-center">
                        <div className="col-12">
                          <div className="label-input-wrap">
                            <input
                              type="tel"
                              id="otp"
                              name="otp"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              minLength={6}
                              maxLength={6}
                              placeholder="Enter OTP"
                            />
                          </div>
                          {!showresendlink && showTimer && (
                            <div className="time-wrap mb-3">
                              <p className="mb-0">Resend OTP in</p>
                              <span className="timer_span d-inline-block">
                                {getTime(otpExp)}s
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="pop-form-btn">
                        <button
                          className="box-hover"
                          type="button"
                          onClick={handleLogin}
                        >
                          Verify
                        </button>
                      </div>
                      {showresendlink && (
                        <div className="time-wrap mt-4">
                          <p className="mb-0" style={{ textAlign: 'center' }}>Not yet received OTP?</p>
                          <button className="border rounded p-2" onClick={resendOtp} type="button">
                            Resend OTP
                          </button>
                        </div>
                      )}
                      <div className="time-wrap mt-4">
                        <p className="mb-0" style={{ textAlign: 'center' }}>Change number?</p>
                        <button className="bg-transparent border-0 text-bold" onClick={() => setOtpSent(false)} type='button' style={{ color: 'maroon' }}>Click here</button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="pop-up-form">
                    <div className="pop-up-top">
                      <div className="heading">
                        <h2 className="head-2">Login</h2>
                      </div>
                    </div>
                    <p>We will send you an OTP on this mobile number and on your registered mail id.</p>
                    <form action="" className="mt-3 mt-sm-0">
                      <div className="row align-items-center m-0">
                        <div className="col-12 px-0">
                          <div className="label-input-wrap">
                            <input
                              type="tel"
                              id="number"
                              name="number"
                              placeholder="Enter Mobile Number"
                              defaultValue={
                                localStorage.getItem("icsmob")
                                  ? localStorage.getItem("icsmob")
                                  : number
                              }
                              required
                              onChange={(e) => setNumber(e.target.value)}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              minLength={10}
                              maxLength={10}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`pop-form-btn ${btnDisabled ? "disabled" : ""
                          }`}
                      >
                        <button
                          className="box-hover"
                          type="button"
                          ref={buttonRef}
                          onClick={handleSendOtp}
                          disabled={btnDisabled}
                        >
                          Get OTP
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;