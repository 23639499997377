import PageHeader from "../components/common/PageHeader";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { Modal } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import moment from "moment";

const Courses = () => {
  const scrollToElement = () => {
    const element = document.getElementById('scrollToAllPages');
    // console.log("elements",element);
    if(element) {
      element.scrollIntoView({behavior : 'smooth'})
    }
  };
  const { user } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [show, setShow] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [popupHeight, setPopupHeight] = useState(0);
  const recaptchaRef = useRef();

  const fetchCourses = async () => {
    try {
      const res = await ApiService.fetchCourses();
      setCourses(res);
    } catch (error) {
      console.log(error);
      notification("error", "Unable to fetch courses.");
    }
  };
  useEffect(() => {
    fetchCourses();
  }, []);

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    setShow(true);
  };
  const handleHideModal = () => {
    setShow(false);
  };

  const handleFormSubmit = async (data) => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      // console.log(token)
      data.token = token;

      await ApiService.createEnquiry({
        service: selectedCourse?._id,
        type: "course",
        ...data,
      });
      notification("success", "Hare Krishna! Your form has been accepted.");
      reset();
      setShow(false);
      recaptchaRef.current.reset();
    } catch (error) {
      console.log(error);
      notification("error", error.response.data.message);
    }
  };

  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };

  const formRef = useCallback((node) => {
    if (show) {
      setPopupHeight(node?.offsetHeight);
    }
    const paraHeight = document.querySelector(".popup-text-wrap");
    paraHeight.style.height = popupHeight + "px";
  });

  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        phone: user.phone,
        email: user.email,
        address: user.address,
      });
    }
  }, [user]);

  useEffect(() => {
    fetchMeta("courses");
  }, []);
  useEffect(()=>{

    scrollToElement();
    },[])

  return (
    <>
      <MetaData title="On-Demand Courses" />
      <PageHeader
        title="ON-DEMAND COURSES"
        imgSrc="/images/page-header/20.webp"
      />
      <section className="course-heading-sec">
        <div className="container">
          <div className="row" id="scrollToAllPages">
            <div className="col-lg-10 mx-auto">
              <div className="heading">
                <h2 className="head-1">PROGRESS ON THE PATH</h2>
                <p>
                  No matter where you are on your spiritual journey we offer a
                  wide cross-section of seminars and courses to help you
                  progress on the path of Bhakti and Krishna Consciousness. Our
                  courses are all free of cost and open to all.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="courses-sec">
        <div className="container">
          <div className="course-card-wrap">
            <div className="row justify-content-center">
              {courses?.length ? (
                <>
                  {courses.map((course, i) => (
                    <div key={i} className="col-lg-4 col-md-6 mb-5">
                      <div className="course-card">
                        <div className="book-top">
                          <div>
                            <figure>
                              <img
                                src={`${process.env.REACT_APP_S3_URL}/uploads/course/${course?.image}`}
                                alt=""
                              />
                            </figure>
                            <h5>
                              {course?.title.split("|")[0]}{" "}
                              {course?.title.split("|")[1]}
                            </h5>
                            <p>{course.excerpt}</p>
                          </div>
                          <div className="course-btn-cls">
                            {course?.redirect ? (
                              <Link
                                className="custom-btn-cls box-hover"
                                to={`${course?.link}`}
                              >
                                {course.buttonText}
                              </Link>
                            ) : (
                              <button
                                className="custom-btn-cls box-hover"
                                onClick={() => handleCourseClick(course)}
                              >
                                {course.buttonText}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <Skeleton height={500} />
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-4" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-2" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton height={30} className="mt-4" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <Skeleton height={500} />
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-4" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-2" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton height={30} className="mt-4" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <Skeleton height={500} />
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-4" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-2" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton height={30} className="mt-4" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <Skeleton height={500} />
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-4" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-2" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton height={30} className="mt-4" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <Skeleton height={500} />
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-4" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-2" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton height={30} className="mt-4" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <Skeleton height={500} />
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-4" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton className="mt-2" />
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <Skeleton height={30} className="mt-4" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <Modal centered show={show} onHide={handleHideModal}>
        <div className="modal-wrap">
          <button className="btn close-svg" onClick={handleHideModal}>
            <svg
              className="close-svg"
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>
          <div className="row">
            <div className="col-md-5 d-sm-block d-none">
              <figure>
                <img
                  src={`${process.env.REACT_APP_S3_URL}/uploads/course/${selectedCourse?.image}`}
                />
              </figure>
            </div>
            <div className="col-md-7">
              <div className="pop-up-form">
                <div className="pop-up-top mb-0 border-0">
                  <div className="heading">
                    <h2 className="head-2">
                      {selectedCourse?.title.split("|")[0]}
                    </h2>
                    <h2 className="head-1">
                      {selectedCourse?.title.split("|")[1]}
                    </h2>
                  </div>
                  <figure className="hare-krishna-logo-fig">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                </div>
                <div className="modal-tab-wrap courses-mod">
                  <Tabs
                    defaultActiveKey="modalForm"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="modalForm" title="REGISTER">
                      <form
                        className="mt-3 mt-sm-0 pt-4"
                        onSubmit={handleSubmit(handleFormSubmit)}
                        ref={formRef}
                      >
                        <div className="row">
                          {selectedCourse?.inputAddOns?.includes(
                            "Name of Child"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="nameOfChild">
                                  Name of Child
                                </label>
                                <input
                                  type="text"
                                  id="nameOfChild"
                                  {...register("nameOfChild", {
                                    required: true,
                                  })}
                                  onKeyPress={(event) => {
                                    if (/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.nameOfChild?.type === "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Age of Child"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="ageOfChild">Age of Child</label>
                                <input
                                  type="text"
                                  id="ageOfChild"
                                  {...register("ageOfChild", {
                                    required: true,
                                    pattern: {
                                      value: /^\d+$/,
                                      message: "Age must be number.",
                                    },
                                  })}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.ageOfChild?.type === "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                                {errors.ageOfChild && (
                                  <small className="error">
                                    {errors.ageOfChild.message}
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Name of Parent"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="nameOfParent">
                                  Name Of Parent
                                </label>
                                <input
                                  type="text"
                                  id="nameOfParent"
                                  {...register("nameOfParent", {
                                    required: true,
                                  })}
                                  onKeyPress={(event) => {
                                    if (/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.nameOfParent?.type === "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes("Name") && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="name">Name</label>
                                <input
                                  type="text"
                                  id="name"
                                  {...register("name", { required: true })}
                                  onKeyPress={(event) => {
                                    if (/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.name?.type === "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes("Email") && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="email">Email</label>
                                <input
                                  type="text"
                                  id="email"
                                  {...register("email", {
                                    required: true,
                                    pattern: {
                                      value:
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      message: "Please enter a valid email",
                                    },
                                  })}
                                />
                                {errors.email?.type === "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                                {errors.email && (
                                  <small className="text-danger error">
                                    {errors.email.message}
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes("Phone") && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="phone">Phone</label>
                                <input
                                  type="text"
                                  id="phone"
                                  maxLength={10}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  {...register("phone", {
                                    required: true,
                                    pattern: {
                                      value: /^[6-9]\d*$/,
                                      message: "Please provide valid phone number",
                                    },
                                    minLength: {
                                      value: 10,
                                      message: "Phone must be minimum 10 digit",
                                    },
                                    maxLength: {
                                      value: 10,
                                      message: "Phone must be maximum 10 digit",
                                    },
                                  })}
                                />
                                {errors.phone?.type === "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                                {errors.phone && (
                                  <small className="text-danger">
                                    {errors.phone.message}
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Location"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="location">Location</label>
                                {/* <select id="location" {...register("location",{required:true})}>
                                                            <option value="">Select location</option>
                                                            <option value="Bhiwandi">Bhiwandi</option>
                                                            <option value="Thane">Thane</option>
                                                            <option  value="Mumbai">Mumbai</option>
                                                            </select> */}
                                <input
                                  type="text"
                                  id="location"
                                  {...register("location", { required: true })}
                                />
                                {errors.location?.type === "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes("Address") && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="address">Address</label>
                                <input
                                  type="text"
                                  id="address"
                                  {...register("address", { required: true })}
                                />
                                {errors.address?.type === "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "No. of singers"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="noOfSingers">
                                  No of singers
                                </label>
                                <input
                                  type="text"
                                  id="noOfSingers"
                                  {...register("noOfSingers", {
                                    required: true,
                                    pattern: {
                                      value: /^\d+$/,
                                      message: "No of singers must be number",
                                    },
                                  })}
                                />
                                {errors.noOfSingers?.type === "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                                {errors.noOfSingers && (
                                  <small className="error">
                                    {errors.noOfSingers.message}
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Size of gathering"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="sizeOfGathering">
                                  Size of gathering
                                </label>
                                <input
                                  type="text"
                                  id="sizeOfGathering"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  {...register("sizeOfGathering", {
                                    required: true,
                                    pattern: {
                                      value: /^\d+$/,
                                      message:
                                        "Size of gathering must be number",
                                    },
                                  })}
                                />
                                {errors.sizeOfGathering?.type ===
                                  "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                                {errors.sizeOfGathering && (
                                  <small className="error">
                                    {errors.sizeOfGathering.message}
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns.includes(
                            "Preferred Time"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="referredTime">
                                  Preferred Time
                                </label>
                                <input
                                  type="time"
                                  id="referredTime"
                                  {...register("referredTime", {
                                    required: true,
                                  })}
                                />
                                {errors.referredTime?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns.includes(
                            "Preferred Date"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="referredDate">
                                  Preferred Date
                                </label>
                                <input
                                  type="date"
                                  min={moment(Date.now()).format("YYYY-MM-DD")}
                                  id="referredDate"
                                  {...register("referredDate", {
                                    required: true,
                                  })}
                                />
                                {errors.referredDate?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Special Note"
                          ) && (
                            <div className="col-12">
                              <div className="label-input-wrap">
                                {/* <label htmlFor="courseNote"> What inspired you to register for the "{selectedCourse?.title.split("|")[0]} {selectedCourse?.title.split("|")[1]}"?</label> */}
                                <label htmlFor="specialNote">
                                  Special Note?
                                </label>
                                <textarea
                                  type="text"
                                  id="specialNote"
                                  {...register("specialNote")}
                                />
                                {/* {errors.specialNote?.type === 'required' && <small className="text-danger">This is required</small> } */}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Course Note"
                          ) && (
                            <div className="col-12">
                              <div className="label-input-wrap">
                                {/* <label htmlFor="courseNote"> What inspired you to register for the "{selectedCourse?.title.split("|")[0]} {selectedCourse?.title.split("|")[1]}"?</label> */}
                                <label htmlFor="courseNote">
                                  {" "}
                                  What are you seeking to gain from this course?
                                </label>
                                <textarea
                                  type="text"
                                  id="courseNote"
                                  {...register("courseNote", {
                                    required: true,
                                  })}
                                />
                                {errors.courseNote?.type === "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Session Note"
                          ) && (
                            <div className="col-12">
                              <div className="label-input-wrap">
                                {/* <label htmlFor="courseNote"> What inspired you to register for the "{selectedCourse?.title.split("|")[0]} {selectedCourse?.title.split("|")[1]}"?</label> */}
                                <label htmlFor="sessionNote">
                                  What are you seeking to gain from this
                                  session?
                                </label>
                                <textarea
                                  type="text"
                                  id="sessionNote"
                                  {...register("sessionNote", {
                                    required: true,
                                  })}
                                />
                                {errors.sessionNote?.type === "required" && (
                                  <small className="text-danger">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="col-12">
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                              ref={recaptchaRef}
                              size="invisible"
                            />
                          </div>
                        </div>

                        <div className="pop-form-btn">
                          <button className="box-hover">
                            Submit
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 448 512"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                            </svg>
                          </button>
                        </div>
                      </form>
                    </Tab>
                    <Tab eventKey="modalDesc" title="VIEW COURSE HIGHLIGHTS">
                      <div className="popup-text-wrap">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: selectedCourse?.description,
                          }}
                          style={{ color: "#7c848d" }}
                          className=""
                        ></div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <GoToTop />
    </>
  );
};

export default Courses;
