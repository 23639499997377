import moment from 'moment';
import React from 'react'
import { useNavigate, NavLink } from 'react-router-dom';
import platform from 'platform'
import notification from '../../helpers/notification';
import fileDownload from 'js-file-download'
import ApiService from '../../api/ApiService';


function EventCard({event}) {
    // console.log(platform.os.family)
    const navigate = useNavigate()
    const handleCalenderDownload=async(file)=>{
      try{
        const res=await ApiService.downloadCalendar(file)  
        fileDownload(res,'calendar-file.ics')
      }catch(error){
          console.log(error)
          notification('error','Unable to download calendar file!')
      }
    }
  return (
    <div className="content-card">

        <figure>
            <img src={`${process.env.REACT_APP_S3_URL}/uploads/event/${event?.image}`} alt=""/>
        </figure>
        <div className="content-info">

            <a href="#">

                <h2>{event?.title}</h2>
            </a>
            <span></span>
            <h5>
                {
            
                moment(event.startDate).format('dddd, D MMMM YYYY')
              
                }
            </h5>
            <p>
                {event?.description.substring(0, 200) + '...'}
            </p>
          
                  <button onClick={() => navigate(`/Event-Details/${event?.slug}`)}  className="custom-btn-cls box-hover my-4">Read more</button>
               
           
            </div>
        </div>
  )
}

export default EventCard