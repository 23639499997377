import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { useEffect, useLayoutEffect, useRef } from "react"

const PageHeader = ({title, imgSrc, hasBtn, description=''}) => {

  const pageHeaderRef = useRef()
  // const handleScrolltoForAllPages = () => {

  //   setTimeout(() => {
  //     const scrollToDonateFromRef = document.getElementById('scrollToAllPages');


  //     if (scrollToDonateFromRef) {
  //       const offset = -600;
  //       const targetScrollTop = scrollToDonateFromRef.offsetTop - offset;

  //       const container = document.documentElement;

  //       container.scrollTop = targetScrollTop;
  //     } else {
  //       console.error("Element scrollToDonateFromRef not found!");
  //     }
  //   }, 1000);
  // };

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    ScrollTrigger.create({
      trigger: pageHeaderRef.current,
      start: 'top top',
      pin: true,
      pinSpacing: false
    })

    return () => {
      ScrollTrigger.getAll().forEach(t => t.kill())
    };

  })

  return (
    <section className="page-header" ref={pageHeaderRef} >
      <div className="container">
        <figure>
            <img src={imgSrc} alt={title} />
        </figure>
          <div className="page-content">
            {
              title &&
              <h2 className="text-uppercase">{title}</h2>
            }
            {
              hasBtn &&
              <button 
                className="box-hover" 
                // onClick={handleScrolltoForAllPages}
                >JOIN THE DEVOTIONAL FAMILY</button>
            }
            {
              description &&
              <p className="_desc">
                {description}
              </p>
            }
          </div>
      </div>
    </section>
  )
}

export default PageHeader