import React from "react";
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'

const JoinFamilyThankYou = () => {
  return (
    <section className="pageNotFound">
      <div className="col-sm-10 mx-auto">
        <div className="pageNotFound-wrapper">
          <div className="row">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <div className="round-404">
                <figure className="mb-0">
                  <img src="/images/common/logo.png" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-4">
              <div className="content-404">
                <p className="textline1-404 mb-0">
                  Your details are submitted successfully.
                </p>
                <p className="textline2-404 py-md-4 py-3">
                 Thank You
                </p>
                <p className="textline3-404 small">
                हरे कृष्ण हरे कृष्ण कृष्ण कृष्ण हरे हरे । हरे राम हरे राम राम राम हरे हरे ॥
                </p>
                <div className="d-flex justify-content-center">
                  <a href="/" className="custom-btn-cls box-hover">
                    <HiOutlineArrowNarrowLeft />
                    Take Me To Home Page
                  </a>
                  <a href="/donate" className="custom-btn-cls box-hover">
                    Donate
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinFamilyThankYou;
