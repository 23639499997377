import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react'
import ApiService from '../../api/ApiService';
import Skeleton from 'react-loading-skeleton';


const YatrasCardWrap = () => {
  return (
   
    <>
    <section className="vrindavan-card">
<div className="container">
  <div className="vrindavan-wrap">
    <div className="row">
      <div className="col-lg-5">
        <figure className="yatra-fig">
        <Skeleton height={500} /> 
        </figure>
      </div>
      <div className="col-lg-7">
        <div className="heading my-auto">
          <div className="mb-md-2 mt-md-0 mt-4">
          <Skeleton width={200} className="mx-auto mt-3"/> 
            <Skeleton width={250} className="mx-auto mt-2 mb-5"/>
          </div>
          <div className="date-div mb-md-2">
            <figure className="date-up">

            </figure>
            <Skeleton width={200} className="mx-auto mt-3"/> 
            <figure className="date-down">
           
            </figure>
          </div>
          <div>
          <Skeleton width={500} className="mx-auto mt-3"/> 
          <Skeleton width={500} className="mx-auto mt-3"/>  
          <Skeleton width={500} className="mx-auto mt-3"/> 
          </div>
          <Skeleton width={300} className="mx-auto mt-3"/> 
        </div>
      </div>
    </div>
  </div>
</div>
</section>
</>
                        
                    
         )
}



export default YatrasCardWrap