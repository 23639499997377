import PageHeader from "../components/common/PageHeader"
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { useEffect, useState } from "react";
import ApiService from "../api/ApiService";

const BhagavadGita = () => {

    return ( 
        <>
            <MetaData title="Bhagavad Gita" />
            <PageHeader title="DOWNLOAD SPIRITUAL LITERATURE" imgSrc="/images/page-header/19.webp" />
            <section className="bhagavadgita-head-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">
                                BHAGAVAD GITA - AS IT IS
                                </h2>
                                <p>
                                The Bhagavad-gita is universally renowned as the jewel of India's spiritual wisdom. Spoken by Lord Krishna, the Supreme Personality of Godhead to His intimate disciple Arjuna, the Gita's seven hundred concise verses provide a definitive guide to the science of self realization. No other philosophical or religious work reveals, in such a lucid and profound way, the nature of consciousness, the self, the universe and the Supreme.
                                </p>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
            <section className="bhagavadgita-card">
                <div className="container">
                    <div className="bhagavadgita-wrap">
                        <div className="row">
                            <div className="col-lg-5">
                                <figure className="bhagavadgita-fig">
                                    <img src="/images/bhagavad-gita/bhagvad-gita.jpg" alt="" />
                                </figure>
                            </div>
                            <div className="col-lg-7">
                                <div className="heading my-auto">
                                    <div className="mb-md-2 mt-md-0 mt-4">

                                        <h2 className="head-2">BHAGAVAD GITA</h2>
                                        <h2 className="head-1">AS IT IS</h2>
                                    </div>
                                    <div className="date-div mb-md-2">
                                        <figure className="date-up">
                                            <img src="/images/yatras/up.svg" alt="" />
                                        </figure>
                                        <h5>His Divine Grace A.C. Bhaktivedanta Swami Prabhupada</h5>
                                        <figure className="date-down">
                                            <img src="/images/yatras/down.svg" alt="" />
                                        </figure>
                                    </div>
                                    <div>
                                        <p>The Bhagavad-gita is universally renowned as the jewel of India's spiritual wisdom. Spoken by Lord Krishna, the Supreme Personality of Godhead to His intimate disciple Arjuna, the Gita's seven hundred concise verses provide a definitive guide to the science of self realization. No other philosophical or religious work reveals, in such a lucid and profound way, the nature of consciousness, the self, the universe and the Supreme.</p>
                                        <p>His Divine Grace A.C. Bhaktivedanta Swami Prabhupada is uniquely qualified to present this English translation and commentary on Bhagavad-gita. He is the world's foremost Vedic scholar and teacher, and he is also the current representative of an unbroken chain of fully self-realized spiritual masters begining with Lord Krishna Himself. Thus, unlike other editions of the Gita, this one is presented as it is--without the slightest taint of adulteration or personal motivation. This edition is certain to stimulate and enlighten with its ancient yet thoroughly timely message.</p>

                                    </div>
                                    <a href="/images/bhagavad-gita/bhagavad-gita-as-it-is.pdf" className="custom-btn-cls text-center box-hover my-4" download>DOWNLOAD BOOK</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <GoToTop/>
        </>
     );
}
 
export default BhagavadGita;